<template>
    <v-dialog v-model="isDialog" max-width="400" persistent>
        <v-card color="indigo-lighten-5" title="Sign up">
            <template v-slot:append>
                <v-icon @click="closeDialog" color="error" icon="mdi-close"></v-icon>
            </template>
            <v-card-subtitle class="text-primary">
                Your credentials are encryped.Feel free
            </v-card-subtitle>
            <v-form ref="form" v-model="valid">
                <v-card-text>
                    <v-text-field v-model="signup.emailid" class="py-1" variant="solo" label="Email address"
                        density="comfortable" hint="example@gmail.com" :rules="emailRules"></v-text-field>
                    <v-text-field v-model="signup.password" class="py-1" variant="solo" label="Create password"
                        density="comfortable" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                        hint="minimun 8 characters" :rules="passwordRules"></v-text-field>
                    <v-text-field v-model="signup.Cpassword" class="py-1" variant="solo" label="Confrim password"
                        density="comfortable" :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="visible2 ? 'text' : 'password'" @click:append-inner="visible2 = !visible2"
                        hint="Password need to same as above" :rules="passwordRules"></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn variant="outlined" color="error" @click="clear">Clear</v-btn>
                    <v-btn variant="outlined" color="success" @click="validateForm">Proceed</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import Eventservice from '../../services/Eventservice';
export default {
    data() {
        return {
            signup: {
                emailid: "",
                password: "",
                Cpassword: "",
            },
            isDialog: false,
            visible: false,
            visible2: false,
            valid: false,
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters'
            ]
        }
    },
    props: {
        dialog: Boolean,
    },
    watch: {
        dialog: {
            handler(newVal) {
                this.isDialog = newVal
            }
        }
    },
    methods: {
        validateForm() {
            this.$refs.form.validate();
            if (this.valid) {
                if (this.signup.password === this.signup.Cpassword) {
                    this.$loader(true)
                    Eventservice.Signup(this.signup)
                        .then(response => {
                            this.$loader(false)
                            if (response.data.status == "S") {
                                this.$showSnackbar(response.data.status, response.data.msg);
                                this.closeDialog();
                            } else {
                                this.$loader(false)
                                this.$showSnackbar(response.data.status, "SignUp Failed");
                                this.closeDialog();
                            }
                        })
                        .catch(error => {
                            this.$loader(false)
                            this.$showSnackbar("E", "Network Error");
                        });
                } else {
                    alert("Password not same confrim Password")
                }
            }
        },
        closeDialog() {
            this.$emit("closeDialog", false)
        },
        clear() {
            this.signup.emailid = "",
                this.signup.password = "",
                this.signup.Cpassword = ""
        }
    },
}
</script>