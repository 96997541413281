<template>
    <div>
        <v-row class="d-flex align-center justify-center ">
            <v-col cols="11" md="5" class="d-flex align-center justify-center ">
                <v-card @click="lumpCalc" color="indigo-lighten-5" width="200" height="200" class="mr-2"
                    title="LUMP Calculator">
                    <v-card-text class="d-flex justify-center align-center">
                        <v-icon size="100">mdi-calculator</v-icon>
                    </v-card-text>
                    <v-card-subtitle>Lumpsum Calculator</v-card-subtitle>
                </v-card>
                <v-card @click="sipCalc" color="purple-lighten-5" width="200" height="200" class="ml-2"
                    title="SIP Calculator">
                    <v-card-text class="d-flex justify-center align-center">
                        <v-icon size="100">mdi-calculator</v-icon>
                    </v-card-text>
                    <v-card-subtitle>SIP Calculator</v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="11" md="5" class="d-flex align-center justify-center ">
                <v-card color="light-blue-lighten-5" class="mt-8 mx-auto overflow-visible" width="400">
                    <v-sheet class="v-sheet--offset mx-auto" color="cyan" elevation="12" max-width="300" rounded="lg">
                        <v-sparkline :labels="labels" :model-value="value" color="white" line-width="2"
                            padding="16"></v-sparkline>
                    </v-sheet>

                    <v-card-text class="pt-0">
                        <div class="text-h6 font-weight-light mb-2">
                            User Login
                        </div>
                        <div class="subheading font-weight-medium text-grey">
                            User App Performance
                        </div>
                        <v-divider class="my-2"></v-divider>
                        <v-icon class="me-2" size="small">
                            mdi-clock
                        </v-icon>
                        <span class="text-caption text-grey font-weight-light">make it and keep it better</span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-col></v-col>
        <lumpsumCalc :digLumpsum="digLumpsum" @closeLumCalc="closeLumCalc()" />
        <sipCalc :digSip="digSip" @closeSipCalc="closeSipCalc()"/>
    </div>
</template>
<script>
import lumpsumCalc from '../calculator/lumpsumCalc.vue';
import sipCalc from '../calculator/sipCalc.vue';
import Eventservice from '../../services/Eventservice';
export default {
    components: {
        lumpsumCalc,
        sipCalc
    },
    created() {
        this.Initialize()
    },
    data() {
        return {
            labels: [],
            value: [],
            digLumpsum: false,
            digSip: false,
        }
    },
    methods: {
        Initialize() {
            this.$loader(true)
            Eventservice.LoginCount()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.labels = response.data.loginCount.date;
                        this.value = response.data.loginCount.counts;
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Failed to load login history");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },

        lumpCalc() {
            this.digLumpsum = true
        },
        sipCalc() {
           this.digSip = true
        },
        closeLumCalc(value) {
            this.digLumpsum = value
        },
        closeSipCalc(value) {
            this.digSip = value
        }
    },
}
</script>
<style>
.v-sheet--offset {
    top: -24px;
    left: 24px;
    position: relative;
}
</style>