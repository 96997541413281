<template>
    <Profile />
</template>
<script>
import Profile from "../components/settings/profile.vue"
export default {
    data() {
        return {

        }
    },
    components: {
        Profile,
    }
}
</script>