<template>
  <v-app>
    <v-app-bar v-if="!login" prominent elevation="0" fixed>
      <v-img src="./assets/HomeLand.png" @click="navigateTo('home')"></v-img>

      <div class="mainNav">
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text" @click="navigateTo('home')">Home</v-btn>
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text" @click="navigateTo('about')">About</v-btn>
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text" @click="navigateTo('services')">Service</v-btn>
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text" @click="navigateTo('contact')">Contact</v-btn>
      </div>
      <v-spacer class="mainNav"></v-spacer>
      <div class="mainNav">
        <v-btn @click="navigateTo('login')" class="mx-2" color="primary" rounded="xl" variant="text"
          tonal><v-icon>mdi-login</v-icon>Login</v-btn>
      </div>
      <v-spacer class="d-md-none"></v-spacer>
      <v-app-bar-nav-icon class="d-md-none" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar v-else prominent elevation="0" fixed>
      <v-img src="./assets/HomeLand.png" @click="navigateTo('home')"></v-img>

      <div class="mainNav">
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text"
          @click="navigateTo('dashboard')">Dashboard</v-btn>
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text"
          @click="navigateTo('portfolio')">Portfolio</v-btn>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn color="orange" rounded="xl" variant="text" v-bind="props">Settings
            </v-btn>
          </template>
          <v-list elevation="1">
            <v-list-item prepend-icon="mdi-account-edit" color="orange" @click="navigateTo('profile')"
              title="Profile"></v-list-item>
            <!-- <v-list-item prepend-icon="mdi-account-cog" color="orange" @click="navigateTo('account')"
              title="Accounts"></v-list-item> -->
          </v-list>
        </v-menu>
        <v-btn class="mx-2" color="orange" rounded="xl" variant="text" @click="navigateTo('contact')">Help</v-btn>
        <v-btn v-if="isAdmin(Admin)" class="mx-2" color="orange" rounded="xl" variant="text"
          @click="navigateTo('admin')">Admin</v-btn>
      </div>
      <v-spacer class="mainNav"></v-spacer>
      <div class="mainNav">
        <v-btn @click="logout" class="mx-2" color="primary" rounded="xl" variant="text"
          tonal><v-icon>mdi-login</v-icon>Logout</v-btn>
      </div>
      <v-spacer class="d-md-none"></v-spacer>
      <v-app-bar-nav-icon class="d-md-none" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-navigation-drawer v-if="!login" v-model="drawer" :location="$vuetify.display.mobile ? 'right' : undefined"
      temporary>
      <v-list><v-list-item color="orange" prepend-icon="mdi-home-analytics" title="Home"
          @click="navigateTo('home')"></v-list-item>
        <v-list-item color="orange" prepend-icon="mdi-office-building" title="About"
          @click="navigateTo('about')"></v-list-item>
        <v-list-item color="orange" prepend-icon="mdi-card-bulleted-settings" title="Service"
          @click="navigateTo('services')"></v-list-item>
        <v-list-item color="orange" prepend-icon="mdi-cellphone-message" title="Contact"
          @click="navigateTo('contact')"></v-list-item>
        <v-list-item color="primary" prepend-icon="mdi-login" title="Login" @click="navigateTo('login')"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-else v-model="drawer" :location="$vuetify.display.mobile ? 'right' : undefined" temporary>
      <v-list><v-list-item color="orange" prepend-icon="mdi-view-dashboard" title="Dashboard"
          @click="navigateTo('dashboard')"></v-list-item>
        <v-list-item color="orange" prepend-icon="mdi-bank" title="Portfolio"
          @click="navigateTo('portfolio')"></v-list-item>


        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item prepend-icon="mdi-cogs" v-bind="props" title="Settings"></v-list-item>
          </template>
          <v-list-item prepend-icon="mdi-account-edit" color="orange" @click="navigateTo('profile')"
            title="Profile"></v-list-item>
          <!-- <v-list-item prepend-icon="mdi-account-cog" color="orange" @click="navigateTo('account')"
            title="Accounts"></v-list-item> -->
        </v-list-group>



        <v-list-item color="orange" prepend-icon="mdi-help-box" title="Help"
          @click="navigateTo('contact')"></v-list-item>
        <v-list-item v-if="isAdmin(Admin)" color="orange" prepend-icon="mdi-security" title="Admin"
          @click="navigateTo('admin')"></v-list-item>
        <v-list-item color="primary" prepend-icon="mdi-logout" title="Logout" @click="logout"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar v-model="$snackbarConfig.snackbar" :timeout="2000" :color="$snackbarConfig.color" location="top right">
      {{ $snackbarConfig.message }}
    </v-snackbar>
    <loader v-if="$loaderConfig.loader" />
    <v-dialog v-if="login" v-model="isDialog" persistent>
      <div>
        <v-row class="d-flex align-center justify-center">
          <v-col class="d-flex align-center justify-center" cols="11">
            <v-card width="600px" color="blue-grey-lighten-5" title="Profile">
              <v-container>
                <v-row class="d-flex align-center justify-center">
                  <v-col class="py-0" cols="11">
                    <v-text-field v-model="profile.name" :rules="[rules.required, rules.alphabets]"
                      prepend-inner-icon="mdi-account-box" label="Name (PAN Card)" density="comfortable"
                      variant="solo"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="11">
                    <v-date-input v-model="profile.dob" :rules="[rules.required]" :max="today" label="Date of Birth"
                      density="comfortable" prepend-inner-icon="mdi-calendar-range" prepend-icon=""
                      variant="solo"></v-date-input>
                  </v-col>
                  <v-col class="py-0" cols="11">
                    <v-radio-group v-model="profile.gender" density="comfortable">
                      <v-row>
                        <v-col><v-radio label="Male" value="Male"></v-radio></v-col>
                        <v-col><v-radio label="Female" value="Female"></v-radio></v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                  <v-col class="py-0" cols="11">
                    <v-text-field v-model="profile.phone" :rules="[rules.required, rules.numeric]"
                      prepend-inner-icon="mdi-cellphone-basic" label="Mobile Number" density="comfortable"
                      variant="solo" type="number"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="11">
                    <v-text-field v-model="profile.pan" :rules="[rules.required, rules.panNumber]"
                      prepend-inner-icon="mdi-numeric" label="PAN Number" density="comfortable"
                      variant="solo"></v-text-field>
                  </v-col>
                  <v-col class="d-flex justify-end" cols="11">
                    <v-btn color="success" @click="submit()" :disabled="!isFormValid" :loading="isLoader"
                      variant="outlined">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
import Eventservice from './services/Eventservice';
import loader from './components/overlay/loader.vue'
export default {
  data: () => ({
    isDialog: (localStorage.getItem('Percentage') != 100 ? true : false),
    isLoader: false,
    Admin: localStorage.getItem('Emailid'),
    profile: {
      name: "",
      emailid: localStorage.getItem('Emailid'),
      dob: null,
      gender: "",
      phone: "",
      pan: "",
      referalcode: "",
    },
    today: new Date().toISOString().substr(0, 10),
    rules: {
      required: value => !!value || 'Required.',
      alphabets: value => /^[A-Za-z\s]+$/.test(value) || 'Only alphabets are allowed.',
      numeric: value => /^[0-9]{10}$/.test(value) || '10 Digit Number',
      panNumber: value => /^[A-Z0-9]{10}$/.test(value) || 'PAN Number must be 10 characters long and alphanumeric.',
      email: value => /.+@.+\..+/.test(value) || 'E-mail must be valid.',
    },
    isLoader: false,
    drawer: false,
    group: null,
    login: localStorage.getItem('Token'),
  }),

  computed: {
    isFormValid() {
      return (
        this.profile.name &&
        this.profile.phone &&
        this.profile.gender &&
        this.profile.dob &&
        this.profile.pan &&
        this.rules.alphabets(this.profile.name) === true &&
        this.rules.numeric(this.profile.phone) === true &&
        this.rules.panNumber(this.profile.pan) === true
      );
    },
  },
  components: {
    loader
  },
  methods: {
    isAdmin(value) {
      if (value == "arun0072019@gmail.com" || value == "tarunkumar7902@gmail.com") {
        return true
      } else {
        return false
      }
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
      this.drawer = false; // Close the drawer after navigation
    },
    logout() {
      localStorage.removeItem('Token');
      localStorage.removeItem('Emailid');
      localStorage.removeItem('Percentage');
      localStorage.removeItem('Name');
      window.location.reload();
      this.$router.push({ name: 'home' });
    },
    ReferalCodeGenerate() {
      let name = this.profile.name.substring(0, 3)
      let number = this.profile.pan.substring(4, 7)
      let number1 = this.profile.phone.substring(5, 9)
      return ("#" + name + number + number1).toUpperCase()
    },
    FormatDate(date) {
      return new Date(date).toLocaleDateString('en-CA'); // Formats to YYYY-MM-DD
    },
    submit() {
      this.$loader(true)
      this.isLoader = true;
      this.profile.referalcode = this.ReferalCodeGenerate()
      this.profile.dob = this.FormatDate(this.profile.dob)
      Eventservice.ProfileApi(this.profile)
        .then(response => {
          if (response.data.status == "S") {
            this.isLoader = false;
            this.$loader(false)
            this.$showSnackbar(response.data.status, response.data.msg);
            localStorage.setItem("Name", this.profile.name);
            localStorage.setItem("Percentage", 100);
            window.location.reload();
          } else {
            this.isLoader = false;
            this.$loader(false)
            this.$showSnackbar(response.data.status, "Profile Not Updated");
            window.location.reload();
          }
        })
        .catch(error => {
          this.$loader(false)
          this.isLoader = false;
          this.$showSnackbar("E", "Network Error");
        });
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
}
</script>
<style>
html,
body {
  overflow-x: hidden;
}

@media (max-width : 768px) {
  .mainNav {
    display: none;
  }
}
</style>