<template>
    <div>
        <clientHoldingsUpdate/>
    </div>
</template>
<script>
import clientHoldingsUpdate from '@/components/admin/clientHoldingsUpdate.vue';
export default {
    data(){
        return{

        }
    },
    components:{
        clientHoldingsUpdate
    }
}
</script>