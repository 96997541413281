<template>
    <div>
        <v-dialog v-model="isDialog" max-width="900" persistent>
            <v-card title="Investment Form" color="blue-grey-lighten-5">
                <template v-slot:append>
                    <v-icon @click="closeDialog" color="error" icon="mdi-close"></v-icon>
                </template>
                <v-card-text>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="6"><v-text-field :disabled="isDisable" v-model="investment.plan"
                                variant="solo" label="Plan" density="comfortable" hide-details></v-text-field></v-col>
                        <v-col cols="12" md="6"><v-autocomplete :disabled="isSip" v-model="investment.minamount"
                                :items="Amount" density="comfortable" variant="solo" label="Investment"
                                hide-details></v-autocomplete></v-col>
                        <v-col cols="12" md="6"><v-text-field :disabled="isDisable" v-model="investment.returns"
                                variant="solo" label="Returns" density="comfortable"
                                hide-details></v-text-field></v-col>
                        <v-col cols="12" md="6"><v-autocomplete :disabled="isSip" v-model="investment.lockinperiod"
                                :items="Periods" density="comfortable" variant="solo" label="Period"
                                hide-details></v-autocomplete></v-col>
                        <v-col cols="12" md="6"><v-text-field :disabled="isDisable" v-model="investment.payout"
                                variant="solo" label="Payout" density="comfortable" hide-details></v-text-field></v-col>
                        <v-col cols="12" md="6"><v-text-field :disabled="isDisable" v-model="investment.emailid"
                                variant="solo" label="Email" density="comfortable" hide-details></v-text-field></v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="Submit" variant="outlined">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Eventservice from '../../services/Eventservice';
export default {
    props: {
        Dialog: Boolean,
        Investment: Object,
        Sip: Boolean,
    },
    watch: {
        Dialog: {
            handler(newVal) {
                this.isDialog = newVal
            }
        },
        Investment: {
            handler(newVal) {
                this.investment.planId = newVal.planId
                this.investment.plan = newVal.plan
                this.investment.minamount = newVal.investment
                this.defaultInvestment = newVal.investment
                this.defaultPeriod = newVal.period
                this.investment.returns = newVal.returns
                this.investment.lockinperiod = newVal.period
                this.investment.payout = newVal.payout
            },
            deep: true,
        },
        Sip: {
            handler(newVal) {
                this.isSip = newVal
            }
        },
        'investment.investment'(newVal) {
            if (newVal === '' || newVal === null) {
                this.investment.minamount = this.defaultInvestment;
            }
        },
        'investment.lockinperiod'(newVal) {
            if (newVal === '' || newVal === null) {
                this.investment.lockinperiod = this.defaultPeriod;
            }
        }
    },
    data() {
        return {
            isDialog: false,
            isSip: false,
            isDisable: true,
            Amount: ['100000', '200000', '500000', '1000000', 'Other'],
            Periods: ['12', '24', '48', 'Other'],
            defaultInvestment: "",
            defaultPeriod: "",
            investment: {
                planId: "",
                plan: "",
                minamount: null,
                returns: "",
                lockinperiod: null,
                payout: "",
                emailid: localStorage.getItem("Emailid"),
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close', false)
        },
        closeSip() {
            this.$emit('closeSip', false)
        },
        Submit() {
            this.$loader(true)
            Eventservice.InsertPreInvest(this.investment)
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, response.data.msg);
                        this.closeDialog()
                        this.closeSip()
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Application Not Submitted");
                        this.closeDialog()
                        this.closeSip()
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                    this.closeDialog()
                    this.closeSip()
                });
        }
    },
}
</script>