<template>
    <div class="mt-6">
        <div>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="11" class="d-flex align-center justify-center">
                    <div class="text1h6 text-indigo font-weight-black">Refer and Earn,<span
                            class="text-black">commissions</span></div>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="12" md="4" class="d-flex align-center justify-start">
                    <div class="homeinvestmenth6" ref="Homeinvestmenth6"></div>
                </v-col>
                <v-col cols="11" md="4">
                    <div class="text2h6 text-orange font-weight-black">Client referral <span
                            class="text-black">commissions</span></div><br>
                    <h2 class="text3h6">Enjoying Homeland? Now share the secret to High-Yield Returns with your friends
                    </h2><br>
                    <h3 class="text4h6 text-indigo text-justify ">We're offering a unique opportunity for them to earn
                        commissions by bringing in new investors.With this innovative strategy clients can receive <span
                            class="text-red text-h6 font-weight-black">2-3% commissions</span> from the investments made
                        by those they refer to our firm.</h3><br><br>
                    <v-row class="d-flex align-center justify-center">
                        <v-btn @click="navigateTo('login')" color="red" variant="outlined">Login to refer</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata from "@/assets/refer1.json";
export default {
    mounted() {
        this.lottie1();
    },
    data() {
        return {

        }
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestmenth6,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
    }
}
</script>
<style>
.text1h6 {
    font-size: 70px;
}

.text2h6 {
    font-size: 40px;
}

@media (max-width: 425px) {
    .text1h6 {
        font-size: 35px;
    }

    .text2h6 {
        font-size: 27px;
    }

    .text3h6 {
        font-size: 18px;
    }

    .text4h6 {
        font-size: 15px;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    .homeinvestmenth6 {
        width: 400px;
    }

    .text1h6 {
        font-size: 60px;
        padding-inline-start: 15px;
    }

    .text2h6 {

        padding-inline-start: 15px;
    }

    .text3h6 {

        padding-inline-start: 15px;
    }

    .text4h6 {

        padding-inline-start: 15px;
    }

}

@media (max-width : 1024px)and (min-width : 769px) {
    .text1h6 {
        font-size: 50px;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {}

@media (max-width : 2560px)and (min-width : 1441px) {
    .homeinvestmenth6 {
        width: 600px;
    }

    .text1h6 {
        padding-inline-start: 150px;
    }

}
</style>