<template>
    <div>
        <adminLayout/>
    </div>
</template>
<script>
import adminLayout from '@/components/admin/adminLayout.vue';
export default {
    data(){
        return{

        }
    },
    components:{
        adminLayout
    }
}
</script>