<template>
    <div>
        <v-row class="d-flex align-center justify-center">
            <v-col class="d-flex align-center justify-center" cols="11">
                <div class="text-h4 font-weight-bold text-orange">Welcome To Admin Portal, <span
                        class="text-h4 font-weight-bold text-black">{{ lUser }}</span></div>
            </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-col v-for="(item, i) in Pages" :key="i" cols="auto">
                <v-card @click="routeTo(item.indicator)" color="blue-grey-lighten-5" max-width="344"
                    :prepend-icon="item.icon" :title="item.title" :subtitle="item.subtitle"
                    append-icon="mdi-open-in-new">
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lUser: localStorage.getItem('Name'),
            Pages: [
                { title: 'Client Holding', subtitle: 'To Update Client Holding', icon: 'mdi-update', indicator: "clientHolding" }]
        }
    },
    methods: {
        routeTo(value) {
            if (value == "clientHolding") {
                this.navigateTo("clientHoldingUpdate")
            }
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
        },
    }
}
</script>