<template>
    <welcome /><br>
    <Dashboard />
    <Calculator />
</template>
<script>
import Dashboard from "../components/dashboard/dashboard.vue"
import Welcome from "../components/dashboard/welcome.vue"
import Calculator from "../components/dashboard/calculator.vue"
export default {
    data() {
        return {

        }
    },
    components: {
        Dashboard,
        Welcome,
        Calculator,
    }
}
</script>