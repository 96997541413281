<template>
    <div>
        <v-bottom-sheet v-model="sheet" persistent>
            <v-card class="text-brown font-weight-bold" title="Wealthy Wellness Fund">
                <template v-slot:append>
                    <v-icon @click="closeSheet" color="error" icon="mdi-close"></v-icon>
                </template>
                <v-card-text>
                    <v-row>
                        <v-col cols="11" md="6">
                            <v-row><v-col class="py-0 text-black " cols="7"><b>Invested on:</b></v-col><v-col
                                    class="py-0 text-black " cols="5">{{
                                        investmentDetails.date }}</v-col></v-row>
                            <v-row><v-col class="py-0 text-black" cols="7"><b>Time of Maturity:</b></v-col><v-col
                                    class="py-0 text-black" cols="5">{{
                                        investmentDetails.maturityDate }}</v-col></v-row>
                        </v-col>
                        <v-col cols="11" md="6">
                            <v-row><v-col class="py-0 text-black" cols="7"><b>Invested Amount:</b></v-col><v-col
                                    class="py-0 text-black" cols="5">₹{{
                                        investmentDetails.investment }}</v-col></v-row>
                            <v-row><v-col class="py-0 text-black" cols="7"><b>Current Returns:</b></v-col><v-col
                                    class="py-0 text-black" cols="5">₹{{
                                        investmentDetails.returnAmnt }}</v-col></v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-0">
                            <apexchart height="380" :options="chartOptions" :series="makeSeries()"></apexchart>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <v-col class=" py-0 d-flex align-center justify-center">
                            <v-btn variant="outlined">Close</v-btn>
                        </v-col>
                    </v-row> -->

                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>
<script>
export default {
    props: {
        isSheet: Boolean,
        pInvestmentDetails: Object,
    },
    data() {
        return {
            sheet: false,
            investmentDetails: {
                date: "",
                maturityDate: "",
                investment: "",
                returnAmnt: "",
                returns: "",
                currentReturns: "",
            },
            chartOptions: {
                chart: {
                    height: 390,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                            },
                            total: {
                                show: true,
                                label: "",
                                formatter: function (w) {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    return 
                                }
                            }
                        },
                        barLabels: {
                            enabled: true,
                            useSeriesColors: true,
                            margin: 8,
                            fontSize: '14px',
                            formatter: function (seriesName, opts) {
                                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                            },
                        },
                    }
                },
                colors: ['#FF9800', '#4CAF50'],
                labels: ['Returns', 'Current'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        }
    },
    methods: {
        closeSheet() {
            this.$.emit("closeSheet", false)
        },
        makeSeries() {
            return [this.investmentDetails.returns,this.investmentDetails.currentReturns];
        },
    },
    watch: {
        isSheet: {
            handler(newVal) {
                this.sheet = newVal
            }
        },
        pInvestmentDetails: {
            handler(newVal) {
                this.investmentDetails.returnAmnt = newVal.returnAmnt
                this.investmentDetails.date = newVal.date
                this.investmentDetails.currentReturns = newVal.currentReturns
                this.investmentDetails.investment = newVal.investment
                this.investmentDetails.maturityDate = newVal.maturityDate
                this.investmentDetails.returns = newVal.returns
                this.chartOptions.plotOptions.radialBar.dataLabels.total.label = "₹"+newVal.returnAmnt
            },
            deep: true,
        }
    },
}
</script>