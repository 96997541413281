import { reactive } from 'vue';

export default {
    install(app) {
        const loaderConfig = reactive({
            loader: false,
        });

        app.config.globalProperties.$loader = (value) => {
            loaderConfig.loader = value;
        };

        app.config.globalProperties.$hideloader = () => {
            loaderConfig.loader = false;
        };

        app.config.globalProperties.$loaderConfig = loaderConfig;
    },
};