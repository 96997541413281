<template>
    <div>
        <div class="servicescon1 ">
            <v-row class="d-flex align-center justify-center">
                <v-col class="d-flex align-center justify-center" cols="11" md="6" v-for="(item, i) in CardList"
                    :key="i">
                    <v-card width="430px" border="warning opacity-50  md" flat>
                        <v-card-title class="text-orange font-weight-bold">
                            {{ item.title }}
                        </v-card-title>
                        <v-card-subtitle class="text-green-accent-4 font-weight-bold">
                            <v-icon left size="small">mdi-radiobox-marked</v-icon>
                            {{ item.status }}
                        </v-card-subtitle>
                        <v-card-text class="text-justify">
                            {{ item.content }}
                        </v-card-text>
                        <v-card-text>
                            <v-row class="d-flex align-center justify-center">
                                <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                    Returns:{{ item.returns }}%
                                    <v-icon color="green-accent-4" size="small">mdi-transfer-up</v-icon>
                                </v-col>
                                <v-divider class="border-opacity-100" color="warning" vertical></v-divider>
                                <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                    Exit load: {{ item.exitload }}%
                                </v-col>
                                <v-divider class="border-opacity-100" color="warning" vertical></v-divider>
                                <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                    Payout:{{ item.payout }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="services3text2 font-weight-bold">
                                    Minium Investment: {{ item.minamount }}
                                </v-col>
                                <v-col class="services3text2 font-weight-bold">
                                    Time to Maturity: {{ item.lockinperiod }} Months
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn variant="outlined" @click="navigateTo('login')" color="primary"
                                text-uppercase>Invest now</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn variant="text" color="black" :disabled="true" text-uppercase>Type
                                :
                                {{ item.type }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-col></v-col>
    </div>
</template>
<script>
import Eventservice from '../../services/Eventservice';
export default {
    created() {
        this.Initialize()
    },
    data() {
        return {
            CardList: []
        }
    },
    methods: {
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
        Initialize() {
            this.$loader(true)
            Eventservice.FetchPlans()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.CardList = response.data.plansList;
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        }
    }
}
</script>
<style>
.services3text2 {
    font-size: 13px;
}

@media (max-width: 425px) {
    .services3text1 {
        font-size: 11.5px;
    }

    .services3text2 {
        font-size: 9.5px;
    }
}

@media (max-width : 1024px)and (min-width : 769px) {
    .servicescon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1000px;
        /* height: 100vh;            */
        margin: 0 auto;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {
    .servicescon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1000px;
        /* height: 100vh;            */
        margin: 0 auto;
    }
}

@media (max-width : 2560px)and (min-width : 1441px) {
    .servicescon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1000px;
        /* height: 100vh;            */
        margin: 0 auto;
    }
}
</style>