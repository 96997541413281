<template>
    <div>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" class="d-flex align-center justify-center">
                <v-card elevation="4" width="940px">
                    <v-card-title class="services2text1 font-weight-bold">
                        INVESTMENT PLANNER
                    </v-card-title>
                    <v-card-text class="services2text2 text-grey-darken-1 font-weight-bold">
                        A tool that helps you find the right funds for your francial goals make me a content for Lumpsum
                        and Sip plans.
                    </v-card-text>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-col cols="4">
                                    <div class="homeinvestmentserv1" ref="Homeinvestmentserv1"></div>
                                </v-col>
                                <v-col cols="12">
                                    <v-card color="grey-lighten-3">
                                        <v-card-title>
                                            Invest in Lumpsum
                                        </v-card-title>
                                        <v-card-text class="text-justify">
                                            Start with a zero-risk, fixed-return investment plan
                                            that secures your money and guarantees stable returns. Invest your corpus
                                            safely and enjoy with assured growth. Our professional fund
                                            managers utilize advanced strategies to ensure your investments yield
                                            maximum returns.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="teal-darken-1" @click="scrollDown(500)" text="Invest in Lumpsum"
                                                variant="outlined"></v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                            </v-col>
                            <v-col cols="12" md="6">
                                <v-col cols="4">
                                    <div class="homeinvestmentserv2" ref="Homeinvestmentserv2"></div>
                                </v-col>
                                <v-col cols="12">
                                    <v-card color="grey-lighten-3">
                                        <v-card-title>
                                            Invest in SIP
                                        </v-card-title>
                                        <v-card-text class="text-justify">
                                            Got a financial goal? Start investing systematically with SIP to achieve
                                            your targets while building a habit of regular saving. Invest small amounts
                                            regularly, benefit from cost averaging and compounding returns, and manage
                                            market volatility. Secure your financial future with SIP today.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="teal-darken-1" @click="scrollDown(1200)" text="Invest in SIP"
                                                variant="outlined"></v-btn>
                                        </v-card-actions>
                                    </v-card></v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-col></v-col>
    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata1 from "@/assets/lumpsum.json";
import animationdata2 from "@/assets/sip.json";
export default {
    mounted() {
        this.lottie1();
        this.lottie2();
    },
    data() {
        return {

        }
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestmentserv1,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata1,
            });
        },
        lottie2() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestmentserv2,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata2,
            });
        },
        scrollDown(value) {
            window.scrollBy({
                top: value, // Adjust this value to control how far down you scroll
                behavior: 'smooth'
            });
        }
    }
}
</script>
<style>
.services2text1 {
    font-size: 50px;
    font-weight: bold;
}

@media (max-width: 425px) {
    .services2text1 {
        font-size: 25px;
    }

}
</style>