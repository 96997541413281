<template>
    <login />
</template>

<script>
import login from "../components/login/login.vue"
export default {
    data() {
        return {

        }
    },
    components: {
        login
    }
}
</script>

<style></style>