<template>
    <div>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <form @submit.prevent="submit" class="contact2form1">
                    <v-text-field prepend-icon="mdi-account" v-model.lazy="value.name" :error-messages="nameError"
                        label="Name" variant="underlined"></v-text-field>

                    <v-text-field prepend-icon="mdi-cellphone" v-model.lazy="value.phonenumber" :counter="10"
                        :error-messages="phoneError" label="Phone Number" variant="underlined"></v-text-field>

                    <v-text-field prepend-icon="mdi-email-multiple" v-model.lazy="value.email" :error-messages="emailError"
                        label="E-mail" variant="underlined"></v-text-field>

                    <v-textarea prepend-icon="mdi-card-text" v-model.lazy="value.description" :counter="200"
                        :error-messages="descriptionError" label="Description" variant="underlined"></v-textarea>

                    <v-btn color="brown-lighten-1" class="me-4 text-success" variant="outlined" type="submit">
                        submit
                    </v-btn>
                    <v-btn color="brown-lighten-1" @click="handleReset" variant="outlined">
                        clear
                    </v-btn>
                </form>
            </v-col>
        </v-row>
        <v-col></v-col>
        <!-- <v-snackbar v-model="snackbarConfig.snackbar" :timeout="2000" :color="snackbarConfig.color"
            location="top right">{{ snackbarConfig.message }}</v-snackbar> -->
    </div>
</template>

<script>
import Eventservice from '../../services/Eventservice';
export default {
    data() {
        return {
            value: {
                name: "",
                phonenumber: "",
                email: "",
                description: ""
            },
            // snackbarConfig: {
            //     snackbar: false,
            //     color: "success",
            //     message: ""
            // },
            error: {
                name: "",
                phonenumber: "",
                email: "",
                description: ""
            },
            touched: {
                name: false,
                phonenumber: false,
                email: false,
                description: false
            }
        }
    },
    computed: {
        nameError() {
            return this.touched.name && !this.value.name ? 'Name is required' : '';
        },
        phoneError() {
            const phonePattern = /^[0-9]{10}$/;
            if (this.touched.phonenumber) {
                if (!this.value.phonenumber) {
                    return 'Phone number is required';
                } else if (!phonePattern.test(this.value.phonenumber)) {
                    return 'Phone number must be 10 digits';
                }
            }
            return '';
        },
        emailError() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (this.touched.email) {
                if (!this.value.email) {
                    return 'Email is required';
                } else if (!emailPattern.test(this.value.email)) {
                    return 'Invalid email address';
                }
            }
            return '';
        },
        descriptionError() {
            return this.touched.description && !this.value.description ? 'Description is required' : '';
        }
    },
    watch: {
        'value.name'() {
            this.touched.name = true;
        },
        'value.phonenumber'() {
            this.touched.phonenumber = true;
        },
        'value.email'() {
            this.touched.email = true;
        },
        'value.description'() {
            this.touched.description = true;
        }
    },
    methods: {
        submit() {
            this.$loader(true)
            this.touched.name = true;
            this.touched.phonenumber = true;
            this.touched.email = true;
            this.touched.description = true;

            if (this.nameError || this.phoneError || this.emailError || this.descriptionError) {
                this.snackbarConfig = {
                    snackbar: true,
                    color: "error",
                    message: "Please fix the errors before submitting"
                };
                return;
            } else {
                Eventservice.Contact(this.value)
                    .then(response => {
                        if (response.data.status == "S") {
                            this.$loader(false)
                            this.$showSnackbar(response.data.status, response.data.msg);
                            this.handleReset()
                        } else {
                            this.$loader(false)
                            this.$showSnackbar(response.data.status, "Try again later...");
                            this.handleReset()
                        }
                    })
                    .catch(error => {
                        this.$loader(false)
                        this.$showSnackbar("E", "Network Error. Try Again");
                    });
            }
        },
        handleReset() {
            this.touched = {
                name: false,
                phonenumber: false,
                email: false,
                description: false
            };
            this.value = {
                name: "",
                phonenumber: "",
                email: "",
                description: ""
            };
        }
    },
}
</script>

<style>
.contact2form1 {
    width: 1000px;
}
</style>
