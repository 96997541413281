<template>
    <div>
        <v-overlay v-model="LoaderOverlay" opacity="0.1" persistent>
            <div class="loaderContainer">
                <div class="loaderStyle" ref="loader"></div>
            </div>
        </v-overlay>
    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata from "@/assets/loader2.json";
export default {
    data() {
        return {
            LoaderOverlay: true,
        }
    },
    mounted() {
        this.initLottieAnimation();
    },
    methods: {
        initLottieAnimation() {
            lottie.loadAnimation({
                container: this.$refs.loader,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
    }
}
</script>
<style>
.loaderContainer {
    display: flex;
    margin-left: 750px;
    height: 100vh;
}
@media (max-width: 425px) {
    .loaderContainer {
    display: flex;
    margin-left:0px;
    height: 100vh;
}
}
.loaderStyle {
    width: 350px;
}

@media (max-width : 1024px)and (min-width : 769px) {
    .loaderContainer {
    display: flex;
    margin-left:350px;
    height: 100vh;
}
}

@media (max-width : 768px)and (min-width : 426px) {
    .loaderContainer {
    display: flex;
    margin-left:220px;
    height: 100vh;
}
}

@media (max-width : 1440px)and (min-width : 1025px) {
    .loaderContainer {
    display: flex;
    margin-left:500px;
    height: 100vh;
}
}

@media (max-width : 2560px)and (min-width : 1441px) {
    .loaderContainer {
    display: flex;
    margin-left:800px;
    height: 100vh;
}
}
</style>