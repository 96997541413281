<template>
    <div>
        <v-dialog v-model="isDialog" max-width="900" persistent>
            <v-card :title="tHead">
                <template v-slot:append>
                    <v-icon @click="closeDialog" color="error" icon="mdi-close"></v-icon>
                </template>
                <v-card-text>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="4">
                            <v-autocomplete :disabled="isEdit" v-model="ClientHoldReg.clientId" label="Client Id"
                                density="comfortable" hide-details :items="clientIds"
                                @update:modelValue="selClientId"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete :disabled="inDisable" v-model="ClientHoldReg.emailId" label="Email Id"
                                density="comfortable" :items="emailIds" hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete :disabled="inDisable" v-model="ClientHoldReg.clientName" label="Name"
                                density="comfortable" :items="names" hide-details></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="4">
                            <v-autocomplete :disabled="isEdit" v-model="ClientHoldReg.title" label="Plan"
                                density="comfortable" :items="planLookup" item-title="plan" item-value="planId"
                                hide-details @update:modelValue="selPlans"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="ClientHoldReg.investment" label="Investment" density="comfortable"
                                hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="ClientHoldReg.period" label="Period" density="comfortable"
                                hide-details></v-text-field>
                        </v-col>
                        <v-col v-if="isEdit" cols="12" md="4">
                            <v-text-field v-model="ClientHoldReg.date" label="Date" density="comfortable"
                                hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="!isEdit" :disabled="isValid" @click="register" variant="outlined"
                        color="success">Submit</v-btn>
                    <v-btn v-else :disabled="isValid1" @click="update" variant="outlined" color="success">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import Eventservice from '../../services/Eventservice';
export default {
    data() {
        return {
            isDialog: false,
            tHead: "Register",
            userLookup: [],
            planLookup: [],
            inDisable: true,
            isEdit: false,
            ClientHoldReg: {
                investmentId: "",
                clientId: "",
                clientName: "",
                emailId: "",
                title: "",
                investment: "",
                period: "",
                date: "",
            },
        }
    },
    props: {
        Dialog: Boolean,
        editMode: Object,
        EditedItems: Object,
    },
    watch: {
        Dialog: {
            handler(newVal) {
                this.isDialog = newVal
            }
        },
        editMode: {
            handler(newVal) {
                this.tHead = newVal.Head
                this.isEdit = newVal.Edit
            },
            deep: true
        },
        EditedItems: {
            handler(newVal) {
                this.ClientHoldReg = newVal
            },
            deep: true
        }
    },
    computed: {
        clientIds() {
            return this.userLookup.map(client => client.clientId);
        },
        emailIds() {
            return this.userLookup.map(client => client.emailId);
        },
        names() {
            return this.userLookup.map(client => client.name);
        },
        isValid() {
            return !(
                this.ClientHoldReg.clientId &&
                this.ClientHoldReg.clientName &&
                this.ClientHoldReg.emailId &&
                this.ClientHoldReg.title &&
                this.ClientHoldReg.investment &&
                this.ClientHoldReg.period
            );
        },
        isValid1() {
            return !(
                this.ClientHoldReg.clientId &&
                this.ClientHoldReg.clientName &&
                this.ClientHoldReg.emailId &&
                this.ClientHoldReg.title &&
                this.ClientHoldReg.investment &&
                this.ClientHoldReg.period &&
                this.ClientHoldReg.date
            )
        }
        // plans() {
        //     return this.planLookup.map(plans => plans.plan);
        // },
    },
    created() {
        this.Initialize()
    },
    methods: {
        Initialize() {
            this.$loader(true)
            Eventservice.FetchFormLookup()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.userLookup = response.data.userLookup
                        this.planLookup = response.data.planLookup
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },
        selClientId(value) {
            this.ClientHoldReg.emailId = this.getClientDetails(value).emailId
            this.ClientHoldReg.clientName = this.getClientDetails(value).name
        },
        selPlans(value) {
            this.ClientHoldReg.investment = this.getPlanDetails(value).investment
            this.ClientHoldReg.period = this.getPlanDetails(value).period
        },
        getClientDetails(clientId) {
            let client = this.userLookup.find(client => client.clientId === clientId);
            if (client) {
                return {
                    emailId: client.emailId,
                    name: client.name
                };
            } else {
                return 'Client not found';
            }
        },
        getPlanDetails(plan) {
            let plans = this.planLookup.find(plans => plans.planId === plan);
            if (plans) {
                return {
                    investment: plans.investment,
                    period: plans.period
                };
            } else {
                return 'Plan not found';
            }
        },
        closeDialog() {
            this.ClientHoldReg = {}
            this.tHead = "Register"
            this.$emit("closeDialog", false)
            this.$emit("reset")
        },
        register() {
            Eventservice.InsertClientHoldingData(this.ClientHoldReg)
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, response.data.msg);
                        this.closeDialog()
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                        this.closeDialog()
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                    this.closeDialog()
                });
        },
        update() {
            Eventservice.UpdateClientHoldingData(this.ClientHoldReg)
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, response.data.msg);
                        this.closeDialog()
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                        this.closeDialog()
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                    this.closeDialog()
                });
        }
    },
}
</script>