import axios from 'axios';

const baseApiClient = axios.create({
  baseURL: 'https://api.homelandinvestment.in',
  // baseURL: 'http://192.168.29.106:2905',
  withCredentials: false,
});


export default {
  Contact(pValue) {
    return baseApiClient.post('/contact', pValue);
  },
  Signup(pSignUpReq) {
    return baseApiClient.post('/signupApi', pSignUpReq);
  },
  LoginApi(pLoginReq) {
    return baseApiClient.post('/loginApi', pLoginReq);
  },
  FetchPlans(pType) {
    const hdr = {
      headers: {
        TYPE: pType
      }
    };
    return baseApiClient.post('/fetchPlans', "", hdr);
  },
  ProfileApi(pBody) {
    return baseApiClient.post('/profileApi', pBody);
  },
  GetClientHoldings() {
    const hdr = {
      headers: {
        USER: localStorage.getItem('Emailid'),
        Authorization: localStorage.getItem('Token')
      }
    };
    return baseApiClient.post('/getClientHoldings', "", hdr);
  },
  GetProfileApi() {
    const hdr = {
      headers: {
        USER: localStorage.getItem('Emailid'),
        Authorization: localStorage.getItem('Token')
      }
    };
    return baseApiClient.post('/getProfileApi', "", hdr);
  },
  GetOtp(pUser) {
    const hdr = {
      headers: {
        USER: pUser,
        INDICATOR: "NOAUTH",
      }
    };
    return baseApiClient.post('/getOtp', "", hdr);
  },
  ForgotPassword(pBody, pOtp) {
    const hdr = {
      headers: {
        OTP: pOtp,
      }
    };
    return baseApiClient.post('/forgotPassword', pBody, hdr);
  },
  LoginCount() {
    const hdr = {
      headers: {
        USER: localStorage.getItem('Emailid'),
        Authorization: localStorage.getItem('Token')
      }
    };
    return baseApiClient.post('/loginCount', "", hdr);
  },
  InsertPreInvest(pBody) {
    const hdr = {
      headers: {
        Authorization: localStorage.getItem('Token')
      }
    };
    return baseApiClient.post('/insertPreInvest', pBody,hdr);
  },
  VerifyToken(pBody) {
    const hdr = {
      headers: {
        Authorization: localStorage.getItem('Token')
      }
    };
    return baseApiClient.post('/verifyToken', pBody,hdr);
  },
  FetchClientHoldingData() {
    return baseApiClient.post('/fetchClientHoldingData');
  },
  FetchFormLookup(){
    return baseApiClient.post('/fetchFormLookup');
  },
  InsertClientHoldingData(pBody) {
    const hdr = {
      headers: {
        USER: localStorage.getItem('Emailid')
      }
    };
    return baseApiClient.post('/insertClientHoldingData', pBody,hdr);
  },
  UpdateClientHoldingData(pBody) {
    const hdr = {
      headers: {
        USER: localStorage.getItem('Emailid')
      }
    };
    return baseApiClient.post('/updateClientHoldingData', pBody,hdr);
  },
}