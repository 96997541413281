<template>
    <div>
        <v-row class="d-flex justify-center align-center">
            <v-col class="d-flex justify-end align-center" cols="11" md="5">
                <v-card color="indigo-lighten-5" width="700" @click="navigateTo('lumpsum')" title="LUMPSUM">
                    <template v-slot:prepend>
                        <v-icon color="indigo" icon="mdi-weight"></v-icon>
                    </template>
                    <v-card-text>
                        Here are some intresting lumsum funds,Invest today
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="d-flex justify-start align-center" cols="11" md="5">
                <v-card color="purple-lighten-5" width="700" @click="navigateTo('sip')" title="SIP">
                    <template v-slot:prepend>
                        <v-icon color="purple" icon="mdi-piggy-bank"></v-icon>
                    </template>
                    <v-card-text>
                        Here are some intresting SIP funds,Start today
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>
<script>

export default {

    data() {
        return {

        }
    },
    methods: {
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
    },
}
</script>
