<template>
    <div>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <h1 class="about3text1 font-weight-bold"><span class="text-red">Core</span> investing principles</h1>
            </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <p class="about3text2">
                    <b>Innovation:</b> Leveraging cutting-edge technology and research to stay
                    ahead in the market and deliver superior investment solutions.<br>
                    <b>Integrity and Trust:</b> We are committed to building lasting relationships with our clients
                    through
                    transparency and ethical practices.<br>
                    <b>Client-Centricity:</b> Prioritizing clients' needs and goals, ensuring their interests come first
                    in all
                    investment decisions.<br>
                    <b>Discipline:</b> Maintain a long-term perspective and stay disciplined through market fluctuations
                    to
                    achieve investment success.
                </p>
            </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-start">
                <h1 class="about3text1 font-weight-bold"><span class="text-red">Core</span> belief</h1>
            </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <p class="about3text2">
                    <b>Long-Term Stability:</b> We focus on long-term, stable returns rather than short-term gains, helping
                    clients achieve sustainable financial growth.<br>
                    <b>Expert Management:</b> Our professional fund managers leverage their expertise to make informed
                    investment decisions, offering personalized advice and risk management.<br>
                    <b>Integrity and Trust:</b> We uphold the highest standards of integrity and transparency, building trust
                    with our clients through ethical practices and honest communication.<br>
                    <b>Client-Centric Focus:</b> We prioritize our clients' interests, ensuring that their financial goals and
                    needs are always at the forefront of our investment strategies.<br>
                </p>
            </v-col>
        </v-row>
        <div>
            <v-col></v-col>
        </div>
    </div>
</template>

<style>
.about3text1 {
    font-size: 90px;
}

.about3text2 {
    font-size: 25px;
    text-align: justify;
}

@media (max-width: 425px) {
    .about3text1 {
        font-size: 50px;
    }

    .about3text2 {
        font-size: 18px;
        text-align: justify;
    }
}
</style>