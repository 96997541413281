<template>
    <div>
        <v-row>
            <v-col class="pb-0" cols="11" md="6">
                <div class="texthome5  mx-2  text-green font-weight-black">Find Income,</div>
                <div class="texthome5   mx-2 font-weight-black">Target your Goals</div><br>
                <h3 class="text1home5">
                    With the hunt for income becoming more challenging than ever,
                    invest today to get a wide range of opportunities to seek yield
                </h3><br><br>
                <v-row class="d-flex align-center justify-center">
                    <v-btn @click="navigateTo('services')" color="green" variant="outlined">Learn more</v-btn>
                </v-row>
            </v-col>
            <v-col class="pb-0 mt-5" cols="12" md="6">
                <v-carousel height="400" :show-arrows="false" cycle hide-delimiters prev-icon="mdi-chevron-left"
                    next-icon="mdi-chevron-right">
                    <v-carousel-item v-for="(item, i) in CardList" :key="i">
                        <v-card class="home4card1" border="warning opacity-50  md" flat>
                            <v-card-title class="text-orange font-weight-bold">
                                {{ item.title }}
                            </v-card-title>
                            <v-card-subtitle class="text-green-accent-4 font-weight-bold">
                                <v-icon left size="small">mdi-radiobox-marked</v-icon>
                                {{ item.status }}
                            </v-card-subtitle>
                            <v-card-text class="text-justify">
                                {{ item.content }}
                            </v-card-text>
                            <v-card-text>
                                <v-row class="d-flex align-center justify-center">
                                    <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                        Returns:{{ item.returns }}%
                                        <v-icon color="green-accent-4" size="small">mdi-transfer-up</v-icon>
                                    </v-col>
                                    <v-divider class="border-opacity-100" color="warning" vertical></v-divider>
                                    <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                        Exit load: {{ item.exitload }}
                                    </v-col>
                                    <v-divider class="border-opacity-100" color="warning" vertical></v-divider>
                                    <v-col class="services3text1 d-flex align-center justify-center" cols="4" md="4">
                                        Payout:{{ item.payout }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="services3text2 font-weight-bold">
                                        Minium Investment: {{ item.minamount }}
                                    </v-col>
                                    <v-col class="services3text2 font-weight-bold">
                                        Time to Maturity: {{ item.lockinperiod }} Months
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="navigateTo('login')" variant="outlined" color="primary"
                                    text-uppercase>Invest
                                    now</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn variant="text" color="black" :disabled="true" text-uppercase>Type
                                    :
                                    {{ item.type }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Eventservice from '../../services/Eventservice';
export default {
    created() {
        this.Initialize()
    },
    data() {
        return {
            CardList: []
        }
    },
    methods: {
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
        Initialize() {
            this.$loader(true)
            Eventservice.FetchPlans()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.CardList = response.data.plansList;
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        }
    },
}
</script>
<style>
.home4card1 {
    width: 425px;
    margin: 0 auto;
}

.home4text1 {
    font-size: 50px;
    font-weight: bolder;
    padding-inline: 10px;
}

.texthome5 {
    font-size: 70px;
    padding-inline-start: 25px;
}

.text1home5 {
    padding-inline-start: 25px;
    margin: 0px;
    text-align: justify;
}

@media (max-width: 425px) {
    .home4card1 {
        width: 330px;
    }

    .texthome5 {
        font-size: 35px;
    }

    .text1home5 {
        padding-inline-end: 25px;
        font-size: 15px;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    .texthome5 {
        font-size: 60px;
    }

}

@media (max-width : 1024px)and (min-width : 769px) {
    .texthome5 {
        font-size: 50px;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {}

@media (max-width : 2560px)and (min-width : 1441px) {
    .homeinvestmenth5 {
        width: 700px;
    }

    .texthome5 {
        padding-inline-start: 150px;
    }

    .text1home5 {
        padding-inline-start: 150px;
    }
}
</style>