<template>
    <div>
        <v-row class="d-flex justify-center align-center">
            <v-col class="d-flex justify-center align-center">
                <div class="text-h2 text-orange font-weight-bold">Welcome</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center align-center">
                <v-avatar color="teal-darken-1" size="80" variant="elevated">
                    <span class="text-h5">{{ ModifyName(lName) }}</span>
                </v-avatar>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="d-flex justify-center align-center">
                <div class="font-weight-bold">{{ lName }}</div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lName: localStorage.getItem('Name')
        }
    },
    methods: {
        ModifyName(value) {
            if (value && value.length >= 2) {
                return value.substring(0, 2).toUpperCase();
            }
            return value ? value.toUpperCase() : '';
        }
    }
}
</script>