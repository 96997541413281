<template>
    <div>
        <!-- <v-row class="d-flex align-center justify-center"> -->
        <v-row class="d-flex align-center justify-center">

            <v-col cols="11" class="d-flex align-center justify-center">
                <h1 class="text-black text-h1 font-weight-bold">Reason for<span
                        class="text-orange text-h4 font-weight-bold"> Homeland</span></h1>
            </v-col>
        </v-row>

        <!-- </v-row> -->
        <!-- <v-row class="d-flex align-center justify-center" > -->
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <h3 class="about2text1 text-justify font-weight-bold">When it comes to investing, traditional options like gold, mutual funds, and fixed deposits have long been popular. However, these investments may not always be the best choice for your financial growth. Gold does not provide regular income in the form of interest or dividends, relying solely on market appreciation, which can be uncertain. Mutual funds, while potentially lucrative, are subject to market risks and can be unpredictable. Fixed deposits generally offer lower returns that may barely keep up with inflation. In contrast, Homeland offers a fixed Higher  returns with Zero Risk  Our expert fund managers employ strategic investment techniques to maximize returns while effectively managing Zero risk, making Homeland a superior choice for your investment needs.</h3>
            </v-col>
        </v-row>

        <!-- </v-row> -->
        <!-- <v-row class="d-flex align-center justify-center"> -->
        <v-col cols="12" class="d-flex align-center justify-center">
            <apexchart height="380" :options="chartOptions" :series="series"></apexchart>
        </v-col>
        <!-- </v-row> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            series: [3, 8, 17, 22, 45],
            chartOptions: {
                chart: {
                    height: 390,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '30%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                            }
                        },
                        barLabels: {
                            enabled: true,
                            useSeriesColors: true,
                            margin: 8,
                            fontSize: '16px',
                            formatter: function (seriesName, opts) {
                                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                            },
                        },
                    }
                },
                colors: ['#1E88E5', '#5E35B1', '#00897B', '#66BB6A', '#FB8C00'],
                labels: ['Bank', 'Fixed deposit', 'Gold', 'Mutual funds', 'Homeland'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false
                        }
                    }
                }]
            }
        }
    },
}
</script>
<style>
@media (max-width: 425px) {
    .about2text1 {
        font-size: 15px;
    }
}
</style>