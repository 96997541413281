<template>
    <div>
        <v-row class="d-flex justify-center align-center">
            <v-col cols="12" class="d-flex justify-center align-center">
                <h1 class="contact1text1 text-brown-lighten-1">Contact<span class="text-h4 text-orange">us</span></h1>
            </v-col>
            <v-col cols="11" md="6" class="d-flex justify-center align-center">
                <p class="font-weight-bold text-justify">Homeland Investment Management. We are dedicated to
                    helping you achieve your financial goals through expert guidance and personalized investment strategies. Whether you are a new investor seeking advice or an existing client needing support, we are here to assist you every step of the
                    way.</p>
            </v-col>
        </v-row>
        <v-col></v-col>
    </div>
</template>
<style>
.contact1text1 {
    font-size: 80px;
    font-weight: bold;
}

@media (max-width: 425px) {
    .contact1text1 {
        font-size: 80px;
    }

}
</style>