<template>
    <div >
        <v-row><v-col cols="12" class=" pt-0 d-flex align-center justify-center ">
                <h1 class="text0">Is mutual fund is better?</h1>
            </v-col></v-row>
        <v-row class="d-flex align-center justify-center ">
            <v-col cols="12" class="d-flex align-center justify-center" md="4">
                <v-sheet class="sheet1 flex-wrap  text-center px-4"  rounded>
                    <div>
                        <h2 class="text11  font-weight-black text-orange">Returns Guaranteed...</h2>

                        <div class="text2  font-weight-medium mb-2">
                            Stay far from mutual fund risks
                        </div>

                        <p class="text3 text-justify mb-4">
                            Mutual funds collect money from multiple investors and invest it in a diversified portfolio
                            of stocks, bonds, and other high-risk securities. Managed by professional fund managers,
                            mutual funds charge relatively high fees but offer an accessible way for individuals to
                            invest without needing extensive market knowledge.

                            At Homeland, we pool money from multiple investors to provide fixed returns through
                            customized investment solutions managed by professional fund managers. We invest in stocks
                            and gold to provide stable Higher returns. Our approach is tailored to meet individual
                            financial goals, offering a personalized investment experience.
                        </p>

                        <v-btn color="orange" @click="navigateTo('contact')" class="text-white mx-3 mb-3" flat>Invest today</v-btn>
                        <v-btn color="orange" @click="navigateTo('about')" class="text-white mx-3  mb-3" variant="outlined">Learn More..</v-btn>
                    </div>
                </v-sheet>
            </v-col>
            <v-col class="d-flex align-center justify-center" cols="11" md="4">
                    <v-timeline density="compact" class="timelineh4">
                        <v-timeline-item size="small" dot-color=orange icon=mdi-numeric-1>
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                    <strong>Guaranteed Returns</strong>
                                </div>
                                <div>Assured on every penny invested</div>
                            </div>
                        </v-timeline-item>
                        <v-timeline-item size="small" dot-color=orange icon=mdi-numeric-2>
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                    <strong>Login in portal</strong>
                                </div>
                                <div>Login to your portal to check your investments</div>
                            </div>
                        </v-timeline-item>
                        <v-timeline-item size="small" dot-color=orange icon=mdi-numeric-3>
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                    <strong>Regular payout</strong>
                                </div>
                                <div>Receive your Quarterly Profits Directly to Your Bank Account</div>
                            </div>
                        </v-timeline-item>
                        <v-timeline-item size="small" dot-color=orange icon=mdi-numeric-4>
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                    <strong>Wealth share</strong>
                                </div>
                                <div>Earn <b>2%</b> Commission from there investment for Referring New Investors</div>
                            </div>
                        </v-timeline-item>
                    </v-timeline>
                </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
    }
}
</script>
<style>

.text11 {
    font-size: 28px;
}

.text2 {
    font-size: 18px;
}

.text3 {
    font-size: 17px;
}

@media (max-width: 425px) {
    

    .sheet1 {
        height:600px;
        width: 400px;
    }

    .text0 {
        font-size: 28px;
        margin-inline: 10px;
    }

    .text11 {
        font-size: 28px;
    }

    .text2 {
        font-size: 18px;
    }

    .text3 {
        font-size: 17px;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    .sheet1 {
        height:400px;
        width: 700px;
    }
}

@media (max-width : 1024px)and (min-width : 769px) {
    .sheet1 {
        height:750px;
        width: 350px;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {
    .sheet1 {
        height:650px;
        width: 350px;
    }
}

@media (max-width : 2560px)and (min-width : 1441px) {
    .sheet1 {
        width: 400px;
        height:500px;
    }
    
}
</style>
