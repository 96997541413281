<template>
    <portfolio />
</template>
<script>
import portfolio from "../components/portfolio/portfolio.vue"
export default {
    components: {
        portfolio
    }
}
</script>