<template>
    <lumpsum />
</template>

<script>
import lumpsum from "../components/dashboard/lumpsum.vue"
export default {
    data() {
        return {

        }
    },
    components: {
        lumpsum
    }
}
</script>