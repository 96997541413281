<template>
    <div>
        <div>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="12" class="d-flex align-center justify-center">
                    <h1 class="about1text1 text-teal">About<span class="text-orange text-h4 ">Homeland</span></h1>
                </v-col>
            </v-row>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="11" md="6">
                    <h3 class=" about1text2 text-justify">
                        At Homeland We are a fiduciary to our clients. We’re investing for the future on behalf of our clients Our purpose is to help more and more people experience financial well-being. Together with our clients, we’re contributing to a more equitable and resilient world – today and for generations to come.
                    </h3>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="12" class="d-flex align-center justify-center">
                    <div class="homeinvestmentabt1" ref="Homeinvestmentabt1"></div>
                </v-col>
            </v-row>
            <v-row class="d-flex align-center justify-center" >
                <v-col cols="12" class="d-flex align-center justify-center">
                    <v-btn @click="navigateTo('login')" variant="outlined"  color="teal">Join Today</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import lottie from "lottie-web";
import animationdata from "@/assets/about.json";
export default {
    mounted() {
        this.lottie1();
    },
    data() {
        return {

        }
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestmentabt1,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
    }
}
</script>

<style>

.about1text1 {
    font-size: 100px;
    font-weight: bold;
}
.homeinvestmentabt1{
    width: 800px;
}
@media (max-width: 425px) {
    .about1text1 {
        font-size: 60px;
        font-weight: bold;
    }
    .about1text2 {
        font-size: 15px;
        font-weight: bold;
    }
}

@media (max-width : 768px)and (min-width : 426px) {}

@media (max-width : 1024px)and (min-width : 769px) {}

@media (max-width : 1440px)and (min-width : 1025px) {}

@media (max-width : 2560px)and (min-width : 1441px) {}
</style>