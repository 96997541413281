<template>
    <div>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <div class="homeinvestmentabt4" ref="Homeinvestmentabt4"></div>
            </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <p class="about4text2">
                    <b>Mission of Homeland</b><br>
                    To empower our clients with prudent investment strategies and personalized financial solutions,
                    ensuring their long-term prosperity and financial well-being.
                    <br><br>
                    <b>Vision of Homeland</b><br>
                    To be a trusted partner in financial stewardship, recognized for our commitment to integrity,
                    innovation, and delivering sustainable value to our clients and communities alike.
                </p>
            </v-col>
        </v-row>
        <v-col></v-col>
    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata from "@/assets/visionmission.json";
export default {
    mounted() {
        this.lottie1();
    },
    data() {
        return {

        }
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestmentabt4,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
    }
}
</script>
<style>
.homeinvestmentabt4 {
    width: 450px;
}

.about4text2 {
    font-size: 25px;
    text-align: justify;
}

@media (max-width: 425px) {
    .about4text2 {
        font-size: 18px;
        text-align: justify;
    }
}
</style>