<template>
    <sip />
</template>

<script>
import sip from "../components/dashboard/sip.vue"
export default {
    data() {
        return {

        }
    },
    components: {
        sip
    }
}
</script>