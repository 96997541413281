<template>
    <div>
        <v-dialog v-model="isFdialog" max-width="400" persistent>
            <v-card color="red-lighten-5" title="Forgot password">
                <template v-slot:append>
                    <v-icon @click="closeFDialog" color="error" icon="mdi-close"></v-icon>
                </template>
                <v-card-text class="pa-0">
                    <v-card class="mb-6" color="warning" variant="tonal">
                        <v-card-text class="text-medium-emphasis text-caption text-justify">
                            Please enter your registered email address to reset your password
                        </v-card-text>
                    </v-card>
                    <v-form ref="form" v-model="valid">
                        <v-card-text>
                            <v-text-field v-model="forgot.emailid" class="py-1" variant="solo" label="Email address"
                                density="comfortable" hint="example@gmail.com" :rules="emailRules"></v-text-field>
                            <v-text-field v-model="forgot.password" class="py-1" variant="solo" label="Create password"
                                density="comfortable" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                hint="minimun 8 characters" :rules="passwordRules"></v-text-field>
                            <v-text-field v-model="forgot.Cpassword" class="py-1" variant="solo"
                                label="Confrim password" density="comfortable"
                                :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible2 ? 'text' : 'password'" @click:append-inner="visible2 = !visible2"
                                hint="Password need to same as above" :rules="passwordRules"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end">
                            <v-btn variant="outlined" color="error" @click="clear">Clear</v-btn>
                            <v-btn variant="outlined" color="success" @click="validateForm">Verify</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isOdialog" max-width="400" persistent>
            <v-card title="Verify your OTP">

                <template v-slot:append>
                    <v-icon v-show="!validating" @click="closeODialog" color="warning" icon="mdi-close"></v-icon>
                </template>

                <v-card-subtitle>
                    Please enter the OTP to
                    verify your account
                </v-card-subtitle>

                <v-card-text>
                    <v-otp-input v-model="otp" :disabled="validating" variant="outlined"></v-otp-input>
                </v-card-text>

                <v-card-subtitle>
                    Your OTP is valid for {{ formattedTime }} minutes
                </v-card-subtitle>

                <v-card-actions>
                    <v-btn :loading="validating" :disabled="isFilled" text="Validate" variant="outlined" border rounded
                        @click="onClick"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Eventservice from '../../services/Eventservice';
export default {
    data() {
        return {
            isFdialog: false,
            isOdialog: false,
            forgot: {
                emailid: "",
                password: "",
                Cpassword: "",
            },
            time: 300, // 5 minutes in seconds
            otp: 0,
            visible: false,
            visible2: false,
            valid: false,
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters'
            ],
            otp: '',
            validating: false,
            interval: null
        }
    },
    props: {
        Fdialog: Boolean,
    },
    watch: {
        Fdialog: {
            handler(newVal) {
                this.isFdialog = newVal
            }
        }
    },
    computed: {
        formattedTime() {
            return this.formatTime(this.time);
        },
        isFilled() {
            return this.otp === null || this.otp === '' || this.otp.length !== 6;
        }
    },
    methods: {
        closeFDialog() {
            this.$emit("closeFDialog", false)
        },
        closeODialog() {
            this.isOdialog = false,
            this.otp = 0
                this.time = 300,
                this.interval = null
        },
        validateForm() {
            this.$refs.form.validate();
            if (this.valid) {
                if (this.forgot.password === this.forgot.Cpassword) {
                    this.$loader(true)
                    Eventservice.GetOtp(this.forgot.emailid)
                        .then(response => {
                            if (response.data.status == "S") {
                                this.$loader(false)
                                this.isOdialog = true
                                this.$showSnackbar(response.data.status, response.data.msg);
                            } else {
                                this.$loader(false)
                                this.$showSnackbar(response.data.status, "Verify Your MailId");
                            }
                        })
                        .catch(error => {
                            this.$loader(false)
                            this.$showSnackbar("E", "Network Error");
                        });
                    this.interval = setInterval(this.decrementTime, 1000); // Decrement every minute
                } else {
                    this.$showSnackbar("E", "Both password need to be Same");
                }
            }
        },
        clear() {
            this.forgot.emailid = "",
                this.forgot.password = "",
                this.forgot.Cpassword = ""
        },
        onClick() {
            this.$loader(true)
            this.validating = true
            Eventservice.ForgotPassword(this.forgot, this.otp)
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, response.data.msg);
                        this.validating = false
                        this.closeODialog()
                        this.closeFDialog()
                        this.clear()
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Failed to Reset");
                        this.validating = false
                        this.closeODialog()
                        this.closeFDialog()
                        this.clear()
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                    this.validating = false
                    this.closeODialog()
                    this.closeFDialog()
                    this.clear()
                });
        },
        startTimer() {
            this.time = 300; // 5 minutes in seconds
            this.interval = setInterval(this.decrementTime, 1000); // Decrement every second
        },
        decrementTime() {
            if (this.time > 0) {
                this.time--;
            } else {
                clearInterval(this.interval);
            }
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
        }
    },
}
</script>

<style></style>