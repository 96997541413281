<template>
    <div>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
            <v-card>
                <v-toolbar color="orange">
                    <v-toolbar-title class="text-white font-weight-bold">LumpSum Calculator</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text="Close" variant="text" @click="closeLumCalc()"></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="pb-0">
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Total Investment</div>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-number-input v-model="Investment" :reverse="false" controlVariant="stacked" inset
                                :max="10000000" :min="1" variant="solo" density="compact" hide-details
                                @blur="checkInvestment"></v-number-input></v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="6"><v-slider v-model="Investment" :max="10000000" :min="5000"
                                :step="500"></v-slider>
                        </v-col></v-row>


                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Returns/yr</div>
                        </v-col>
                        <v-col cols="6" md="3"><v-number-input v-model="returns" :reverse="false"
                                controlVariant="stacked" inset :max="50" :min="1" variant="solo" density="compact"
                                hide-details @blur="checkReturns"></v-number-input></v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="6"><v-slider v-model="returns" :max="50" :min="1" :step="0.1"></v-slider>
                        </v-col></v-row>


                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Time Period</div>
                        </v-col>
                        <v-col cols="6" md="3"><v-number-input v-model="timePeriod" :reverse="false"
                                controlVariant="stacked" inset :max="40" :min="1" variant="solo" density="compact"
                                hide-details @blur="checkTimePeriod"></v-number-input></v-col>
                    </v-row>
                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="12" md="6"><v-slider v-model="timePeriod" :max="40" :min="1" :step="1"></v-slider>
                        </v-col></v-row>



                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Invested Amount</div>
                        </v-col>
                        <v-col class="d-flex justify-end" cols="6" md="3">
                            <div>{{ formatWithCommas(Investment) }}</div>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Est.returns</div>
                        </v-col>
                        <v-col class="d-flex justify-end" cols="6" md="3">
                            <div>{{ formatWithCommas(totalReturns.toFixed(2)) }}</div>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex align-center justify-center">
                        <v-col cols="6" md="3">
                            <div>Total value</div>
                        </v-col>
                        <v-col class="d-flex justify-end" cols="6" md="3">
                            <div>{{ formatWithCommas(futureValue.toFixed(2)) }}</div>
                        </v-col>
                    </v-row>

                    <div class="chartCalc">
                        <v-row class="d-flex align-center justify-center">
                            <v-col class="pb-0" cols="6" md="3">
                                <apexchart height="300" type="donut" :options="chartOptions" :series="series">
                                </apexchart>
                            </v-col>
                        </v-row>
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { VNumberInput } from 'vuetify/labs/VNumberInput'
export default {
    components: {
        VNumberInput,
    },
    props: {
        digLumpsum: Boolean
    },
    watch: {
        Investment: {
            handler: 'calculateLumpsum',
            immediate: true
        },
        returns: {
            handler: 'calculateLumpsum',
            immediate: true
        },
        timePeriod: {
            handler: 'calculateLumpsum',
            immediate: true
        },
        digLumpsum: {
            handler(newVal) {
                this.dialog = newVal;
            }
        }
    },
    data() {
        return {
            dialog: false,
            returns: 30,
            Investment: 5000,
            timePeriod: 3,
            futureValue: 0,
            totalReturns: 0,
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                labels: ['Investment', 'Total Returns'],
                dataLabels: {
                    enabled: false,
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },
    methods: {
        calculateLumpsum() {
            const rate = this.returns / 100;
            this.futureValue = this.Investment * Math.pow(1 + rate, this.timePeriod);
            this.totalReturns = this.futureValue - this.Investment;
            this.series = [this.notNull(this.Investment), this.notNull(this.totalReturns)];
        },
        checkReturns() {
            if (!this.returns || this.returns === null || this.returns === '') {
                this.returns = 1;
            }
        },
        checkInvestment() {
            if (!this.Investment || this.Investment === null || this.Investment === '') {
                this.Investment = 5000;
            }
        },
        checkTimePeriod() {
            if (!this.timePeriod || this.timePeriod === null || this.timePeriod === '') {
                this.timePeriod = 3;
            }
        },
        notNull(value) {
            return isNaN(value) || value === undefined ? 1 : value;
        },
        formatWithCommas(value) {
            if (value) {
                const numericValue = parseFloat(value.toString().replace(/,/g, '')); // Remove commas
                return value = isNaN(numericValue) ? 0 : numericValue.toLocaleString(); // Format with commas
            }
        },
        closeLumCalc() {
            this.$emit("closeLumCalc",false)
        }
    }
}
</script>
<style>
@media (max-width: 425px) {
    .chartCalc {
        display: none;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    .chartCalc {
        display: none;
    }
}

@media (max-width : 1024px)and (min-width : 769px) {
    .chartCalc {
        display: none;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {}

@media (max-width : 2560px)and (min-width : 1441px) {}
</style>