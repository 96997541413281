import { createRouter, createWebHashHistory } from 'vue-router'
import Eventservice from '../services/Eventservice';
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Portfolio from '../views/Portfolio.vue'
import Profile from '../views/Profile.vue'
import Lumpsum from '../views/Lumpsum.vue'
import Sip from '../views/Sip.vue'
import Admin from '../views/Admin.vue'
import ClientHoldingUpdate from '../views/ClientHoldingUpdate.vue'

// Sample authentication check function
const isAuthenticated = () => {
  // Implement your logic to check if the user is authenticated
  // For example, checking if a token exists in localStorage
  return !!localStorage.getItem('Token');
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresGuest: true } // Only accessible to unauthenticated users
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { requiresGuest: true } // Only accessible to unauthenticated users
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: { requiresGuest: true } // Only accessible to unauthenticated users
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresGuest: true } // Only accessible to unauthenticated users
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: Portfolio,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/lumpsum',
    name: 'lumpsum',
    component: Lumpsum,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/sip',
    name: 'sip',
    component: Sip,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
  {
    path: '/clientHoldingUpdate',
    name: 'clientHoldingUpdate',
    component: ClientHoldingUpdate,
    meta: { requiresAuth: true } // Only accessible to authenticated users
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const TokenVerification = async () => {
  try {
    const response = await Eventservice.VerifyToken();
    if (response.data.status === "S") {
      return "S";
    } else {
      return response.data.status;
    }
  } catch (error) {
    console.log("ROUTERERROR", error);
    return "E";
  }
};

// Navigation guard to handle route access based on authentication
router.beforeEach(async (to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo(0, 0);

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({ name: 'login' }); // Redirect to login if not authenticated
    } else {
      const result = await TokenVerification();
      if (result === 'S') {
        next(); // Token is valid, proceed to the route
      } else {
        // Token is invalid or expired, clear the token and redirect to login
        localStorage.removeItem('Token');
        localStorage.removeItem('Emailid');
        localStorage.removeItem('Percentage');
        localStorage.removeItem('Name');
        next({ name: 'login' });
        window.location.reload();
      }
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (isAuthenticated()) {
      next({ name: 'dashboard' }); // Redirect authenticated users to the dashboard
    } else {
      next(); // Allow navigation if not authenticated
    }
  } else {
    next(); // Proceed to routes that do not require specific authentication checks
  }
});

export default router;
