<template>
    <div >
        <v-timeline direction="horizontal" >
            <v-timeline-item  dot-color="blue-grey" icon=mdi-numeric-1 >
                <template v-slot:opposite>
                    Step 1
                    <h2 class="textforstep7 text-teal" >Refer your friend</h2>
                </template>
                <div>
                    <div class="text-h6"><v-icon  size="x-large">mdi-account-multiple-check</v-icon></div>
                    <p class="textforpara7 text-orange font-weight-black" >
                        Earn Commissions, by Referring your Friends to Homeland Family
                    </p>
                </div>
            </v-timeline-item>

            <v-timeline-item dot-color="blue-grey" icon=mdi-numeric-2>
                <template v-slot:opposite>
                   Step 2
                   <h2 class="textforstep7 text-teal" >Your friend invests</h2>
                </template>
                <div>
                    <div class="text-h6"><v-icon size="x-large">mdi-finance</v-icon></div>
                    <p class="textforpara7 text-orange font-weight-black">
                        Earn Commission, you'll earn a 2-3% commission on every investment they make.
                    </p>
                </div>
            </v-timeline-item>

            <v-timeline-item dot-color="blue-grey" icon=mdi-numeric-3 >
                <template v-slot:opposite>
                    Step 3
                    <h2 class="textforstep7 text-teal" >You receive referral bonus</h2>
                </template>
                <div>
                    <div class="text-h6"><v-icon size="x-large">mdi-cash-multiple</v-icon></div>
                    <p class="textforpara7 text-orange font-weight-black" >
                        After they invested referral money credited to your bank account.
                    </p>
                </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>
<style>
@media (max-width: 425px) {
.textforpara7{
    font-size: 10px;
}
.textforstep7{
    font-size: 15px;
}
}
</style>