<template>
    <div>
        <home />
        <home2 />
        <home4 /><br><br><br>
        <home3 />
        <home6 /><br>
        <home7 />
        <v-footer dark padless class="pa-0">
            <v-card flat tile color="blue-grey-lighten-5" class="mt-5 text-center">
                <v-card-text>
                    <v-btn v-for="icon in icons" :key="icon.name" class="mx-4" icon :href="icon.link" target="_blank">
                        <v-icon size="24px">
                            {{ icon.name }}
                        </v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text class="text-justify pt-0">
                    At Homeland Investment Management, we are dedicated to guiding individuals
                    towards a secure financial future through our specialized expertise in
                    fixed-return solutions. As fiduciaries, we prioritize the interests of our
                    clients above all else, ensuring that every investment decision is made with
                    their long-term prosperity in mind. Our comprehensive suite of services
                    encompasses prudent advice, risk management, and a steadfast commitment to
                    building trust and integrity throughout our client relationships. With Homeland,
                    you can confidently navigate the complexities of investment, knowing that your
                    financial well-being is in capable hands. Trust us to safeguard your future and
                    provide peace of mind every step of the way.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="">
                    Homeland since 2024 — <strong>© Rights reserved Version-1.1</strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>


</template>
<script>
import home from "../components/home/home.vue"
import home2 from "../components/home/home2.vue"
import home3 from "../components/home/home3.vue"
import home4 from "../components/home/home4.vue"
import home6 from "../components/home/home6.vue"
import home7 from "../components/home/home7.vue"
export default {
    data() {

        return {
            icons: [
                // { name: "mdi-facebook", link: "https://www.facebook.com" },
                { name: "mdi-gmail", link: "mailto:homelandinvestment.in@gmail.com" },
                // { name: 'mdi-twitter', link: 'https://twitter.com' },
                { name: 'mdi-linkedin', link: 'https://www.linkedin.com/company/homeland-investment-management-company/' },
                { name: "mdi-instagram", link: "https://www.instagram.com/homeland_investment?igsh=dzllYXJtNXdrMXFj&utm_source=qr" }
            ],
        }

    },
    components: {
        home,
        home2,
        home3,
        home4,
        home6,
        home7,
    }
}
</script>
