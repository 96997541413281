<template>
    <div>
        <!-- <v-row class="d-flex align-center justify-center">
            <v-col cols="11" class="d-flex align-center justify-start"><div class="text-h4 font-weight-bold text-blue-grey-darken-2" ><v-icon class="mx-2" size="35px" color="" >mdi-book-plus</v-icon>Client Holding Update</div></v-col>
        </v-row> -->
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" class="d-flex align-center justify-center">
                <v-card class="text-white" title="Client Holding Update" color="orange">
                    <v-card-title><v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="search" variant="solo" density="compact" hide-details></v-text-field></v-card-title>
                    <template v-slot:prepend>
                        <v-icon color="white" icon="mdi-book-plus"></v-icon>
                    </template>
                    <template v-slot:append>
                        <v-btn @click="Register" variant="outlined" rounded
                            color="white"><v-icon>mdi-plus</v-icon>Add</v-btn>
                    </template>
                    <v-data-table :search="search" :headers="headers" :items="ClientDataList">
                        <template v-slot:item.actions="{ item }">
                            <v-icon class="me-2" size="small" @click="editItem(item)">
                                mdi-notebook-edit-outline
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <clientHoldingsRegister :Dialog="Dialog" @closeDialog="closeDialog" @reset="reset" :editMode="editMode" :EditedItems="EditedItems" />
    </div>
</template>
<script>
import Eventservice from '../../services/Eventservice';
import clientHoldingsRegister from './clientHoldingsRegister.vue';
export default {
    created() {
        this.Initialize()
    },
    components: {
        clientHoldingsRegister
    },
    data() {
        return {
            search:"",
            Dialog: false,
            editMode:{
                Edit:false,
                Head:"Edit"
            },
            headers: [
                {
                    title: 'Id',
                    align: 'start',
                    sortable: false,
                    key: 'investmentId',
                },
                { title: 'Client Id', key: 'clientId', align: 'center' },
                { title: 'Name', key: 'clientName', align: 'center' },
                { title: 'Email Id', key: 'emailId', align: 'center' },
                { title: 'Plan', key: 'title', align: 'center' },
                { title: 'Investment', key: 'investment', align: 'center' },
                { title: 'Period', key: 'period', align: 'center' },
                { title: 'Status', key: 'status', align: 'center' },
                { title: 'Date', key: 'date', align: 'center' },
                { title: 'Edit', key: 'actions', align: 'center' },
            ],
            ClientDataList: [],
            EditedItems: {
                investmentId:"",
                clientId: "",
                clientName: "",
                emailId: "",
                title: "",
                investment: "",
                period: "",
                date:"",
                renderCount:0,
            },
        }
    },
    methods: {
        Initialize() {
            this.$loader(true)
            Eventservice.FetchClientHoldingData()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.ClientDataList = response.data.clientHoldings;
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },
        reset(){
            this.Initialize()
        },
        Register() {
            this.Dialog = true;
        },
        closeDialog(value) {
            this.Dialog = value;
            this.editMode.Edit = value;
        },
        editItem(value) {
            this.EditedItems.investmentId = value.investmentId
            this.EditedItems.clientId = value.clientId
            this.EditedItems.clientName = value.clientName
            this.EditedItems.emailId = value.emailId
            this.EditedItems.investment = value.investment
            this.EditedItems.period = value.period
            this.EditedItems.title = value.title
            this.EditedItems.date = value.date
            this.EditedItems.renderCount = this.EditedItems.renderCount+1
            this.editMode.Edit = true;
            this.Dialog = true;
        }
    },
}
</script>