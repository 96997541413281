<template>
    <div>
        <Comfrimation :clientCode="Profile.clientCode" :Dialog="Dialog" @closeDialog="closeDialog" />
        <v-row><v-col cols="12" class="d-flex align-center justify-center">
                <div class="text-h3 font-weight-bold">Hello!</div>
            </v-col>
            <v-col class="d-flex align-center justify-center" cols="12">
                <v-avatar color="orange" size="80" variant="elevated">
                    <span class="text-h5">{{ ModifyName(Profile.name) }}</span>
                </v-avatar>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat v-model="Profile.name" label="Name (as on Pan Card)" variant="solo" readonly
                    hide-details></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat v-model="Profile.clientCode" label="Client Code" variant="solo" readonly
                    hide-details></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat variant="solo" hide-details v-model="Profile.dob" readonly
                    label="Date of Birth"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat readonly variant="solo" hide-details v-model="Profile.mobilenumber"
                    label="Mobile Number"></v-text-field>
                <!-- <v-text-field flat append-inner-icon="mdi-pencil" readonly @click:append-inner="onClick" variant="solo"
                    hide-details v-model="Profile.mobilenumber" label="Mobile Number"></v-text-field> -->
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <!-- <v-text-field flat append-inner-icon="mdi-pencil" readonly @click:append-inner="onClick" variant="solo"
                    hide-details v-model="Profile.email" label="Email"></v-text-field> -->
                <v-text-field flat readonly variant="solo" hide-details v-model="Profile.email"
                    label="Email"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat variant="solo" hide-details v-model="Profile.pan" readonly
                    label="PAN Number"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat variant="solo" hide-details v-model="Profile.gender" readonly
                    label="Gender"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
            <v-col class="py-1" cols="11" md="6">
                <v-text-field flat variant="solo" hide-details v-model="Profile.referalcode" readonly
                    label="Referal Code"></v-text-field>
            </v-col>
        </v-row>
        <v-col></v-col>
    </div>
</template>
<script>
import Comfrimation from "./confrimation.vue"
import Eventservice from '../../services/Eventservice';
export default {
    created() {
        this.Initialize()
    },
    components: {
        Comfrimation,
    },
    data() {
        return {
            tabs: null,
            Dialog: false,
            lName: localStorage.getItem('Name'),
            Profile: {
                name: "",
                clientCode: "",
                dob: "",
                mobilenumber: "",
                email: "",
                pan: "",
                gender: "",
                referalcode: ""
            }
        }
    },
    methods: {
        onClick() {
            this.Dialog = true
        },
        closeDialog(value) {
            this.Dialog = value
        },
        Initialize() {
            this.$loader(true)
            Eventservice.GetProfileApi()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.Profile.clientCode = response.data.profile.clientId
                        this.Profile.dob = response.data.profile.dob
                        this.Profile.email = response.data.profile.emailid
                        this.Profile.gender = response.data.profile.gender
                        this.Profile.name = response.data.profile.name
                        this.Profile.pan = response.data.profile.pan
                        this.Profile.mobilenumber = response.data.profile.phone
                        this.Profile.referalcode = response.data.profile.referalcode
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Get Profile");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },
        ModifyName(value) {
            if (value && value.length >= 2) {
                return value.substring(0, 2).toUpperCase();
            }
            return value ? value.toUpperCase() : '';
        }
    }

}
</script>
<style></style>