<template>
    <div>
        <div>
            <v-row class="d-flex align-justify justify-center">
                <v-col cols="12" class="d-flex align-justify justify-center">
                    <h1 class="services1text1 text-blue-grey">Services<span
                            class="text-h6 text-orange">That guaranteed</span></h1>
                </v-col>
                <v-col cols="11" md="6">
                    <p class="font-weight-bold text-justify">
                        we provide tailored investment services designed to help you achieve your financial goals with
                        ease and confidence. Enjoy fixed high returns with zero risk, ensuring substantial growth and
                        capital security. Our expert fund managers maximize your returns with a diversified portfolio of
                        stocks and gold. Choose between flexible lumpsum and Systematic Investment Plans (SIP) based on
                        your convenience. We offer personalized solutions and competitive fees, ensuring more of your
                        money works for you. Invest smartly and securely with Homeland, and enjoy a prosperous financial
                        future with our superior investment services.
                    </p>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<style>
.services1text1 {
    font-size: 80px;
    font-weight: bold;
}

@media (max-width: 425px) {
    .services1text1 {
        font-size: 50px;
    }

}
</style>