<template>
    <div class="mb-12">
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-center">
                <h1 class="text-red">Why choose us?</h1>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
                <h3 class="content1">We specialize in providing safe and reliable ways to invest money focusing on
                    fixed-return
                    solutions. As fiduciaries, we always put our clients' interests first. Our services include helping
                    clients invest their money wisely, giving them advice, managing risks, and building trust and
                    integrity while investing for our clients' futures.</h3>
            </v-col>
        </v-row>
        <v-row class="mx-4 mb-16"><v-col class=" d-flex align-center justify-end" cols="12" sm="6" lg="6"><v-card
                    color="indigo" variant="tonal" width="500px">
                    <v-card-title class=" d-flex align-center justify-center"><v-icon
                            size="50px">mdi-finance</v-icon></v-card-title>
                    <v-card-subtitle class="d-flex align-center justify-center">No more risk</v-card-subtitle>
                    <v-card-item>
                        <p class="para1">We specialize in risk-free investment solutions for substantial, predictable
                            returns, securing your financial future. As fiduciaries, we prioritize your interests,
                            providing expert guidance, personalized advice, and meticulous risk management. Our focus on
                            fixed-return strategies ensures significant profits without market volatility. Invest
                            confidently with us for stable, long-term financial growth built on trust and integrity."
                        </p>
                    </v-card-item>
                </v-card></v-col>
            <v-col cols="12" class=" d-flex align-center justify-start" sm="6" lg="6"><v-card color="indigo"
                    variant="tonal" width="500px">
                    <v-card-title class="d-flex align-center justify-center"><v-icon
                            size="50px">mdi-cash-multiple</v-icon></v-card-title>
                    <v-card-subtitle class="d-flex align-center justify-center">Higher profit</v-card-subtitle>
                    <v-card-item>
                        <p class="para1">Profit underscores our commitment to exceptional financial outcomes through
                            safe, reliable investments. Specializing in secure strategies and fixed returns, we
                            prioritize client interests as fiduciaries. We offer expert guidance in decisions and risk
                            management, fostering trust. With dedication to prosperous futures, our investments ensure
                            significant profitability and long-term success.</p>
                    </v-card-item>
                </v-card></v-col>
            <v-col cols="12" class=" d-flex align-center justify-end" sm="6" lg="6"><v-card color="indigo"
                    variant="tonal" width="500px">
                    <v-card-title class="d-flex align-center justify-center"><v-icon
                            size="50px">mdi-cellphone-link</v-icon></v-card-title>
                    <v-card-subtitle class="d-flex align-center justify-center">Application service</v-card-subtitle>
                    <v-card-item>
                        <p class="para1">Customized for application services, we are committed fiduciaries dedicated to your best interests. Benefit from our expert guidance, personalized advice, and rigorous risk management. Our emphasis on fixed-return strategies guarantees substantial profits with minimal market volatility. Invest confidently with us for dependable, long-term financial growth grounded in trust and integrity.</p>
                    </v-card-item>
                </v-card></v-col>
            <v-col cols="12" class=" d-flex align-center justify-start" sm="6" lg="6"><v-card color="indigo"
                    variant="tonal" width="500px">
                    <v-card-title class="d-flex align-center justify-center"><v-icon
                            size="50px">mdi-face-agent</v-icon></v-card-title>
                    <v-card-subtitle class="d-flex align-center justify-center">Quick customer service</v-card-subtitle>
                    <v-card-item>
                        <p class="para1">We prioritize rapid customer service to ensure prompt solutions and satisfaction. Our dedicated team is committed to swiftly addressing your inquiries, ensuring your needs are met with care and responsiveness. Whether you need assistance, have questions, or seek guidance, count on our timely support for a seamless experience that prioritizes your convenience and satisfaction above all else.</p>
                    </v-card-item>
                </v-card></v-col>
        </v-row>
    </div>
</template>
<style>
.para1 {
    text-align: justify;
}

@media (max-width: 425px) {
    .content1 {
        margin-inline: 25px;
        text-align: justify;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    .content1 {
        margin-inline: 30px;
        text-align: justify;
    }
}

@media (max-width : 1024px)and (min-width : 769px) {
    .content1 {
        margin-inline: 50px;
        text-align: justify;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {
    .content1 {
        margin-inline: 120px;
        text-align: justify;
    }
}

@media (max-width : 2560px)and (min-width : 1441px) {
    .content1 {
        margin-inline: 250px;
        text-align: justify;
    }
}
</style>