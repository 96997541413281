<template>
    <div>
        <signup :dialog="dialog" @closeDialog="closeDialog()" />
        <forgotpassword :Fdialog="Fdialog" @closeFDialog="closeFDialog()" />
        <v-row class="d-flex align-center justify-center">
            <v-col cols="11" md="6" class="disp1">
                <div class="login" ref="login"></div>
            </v-col>
            <v-col cols="11" md="6" class="d-flex align-center justify-center">
                <div>
                    <v-img class="disp2 mx-auto my-6" max-width="300" src="../../assets/HomeLand.png"></v-img>
                    <div class="d-md-none d-flex align-center justify-center my-6 font-weight-bold text-h4">Login</div>
                    <v-card class="mx-auto pa-6" elevation="8" max-width="448" rounded="lg">
                        <div class="text-subtitle-1 text-medium-emphasis">Account</div>
                        <v-form ref="form" v-model="valid">
                            <v-text-field v-model="loginDetails.emailid" :rules="emailRules" density="compact"
                                placeholder="Email address" required prepend-inner-icon="mdi-email-outline"
                                variant="outlined"></v-text-field>
                            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                                Password
                                <a class="cursor-pointer text-caption text-decoration-none text-orange"
                                    @click="forgotpassword">
                                    Forgot login password?
                                </a>
                            </div>
                            <v-text-field v-model="loginDetails.password" :rules="passwordRules"
                                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible ? 'text' : 'password'" density="compact"
                                placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline"
                                variant="outlined" @click:append-inner="visible = !visible"></v-text-field>
                            <v-card class="mb-12" color="warning" variant="tonal">
                                <v-card-text class="text-medium-emphasis text-caption text-justify">
                                    Warning: After 5 consecutive failed login attempts, your account will be temporarily
                                    locked for
                                    30 minutes. If you must login now, you can also click "Forgot login password?"
                                    below to reset the
                                    login password.
                                </v-card-text>
                            </v-card>
                            <v-btn class="mb-8" color="orange" size="large" variant="tonal" block @click="validateForm">
                                Log In
                            </v-btn>
                        </v-form>
                        <v-card-text class="text-center">
                            <v-btn class="text-orange text-decoration-none" @click="signup" variant="outlined">
                                Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-col></v-col>
    </div>
</template>

<script>
import signup from "./signup.vue"
import forgotpassword from "./forgotpassword.vue";
import lottie from "lottie-web";
import animationdata from "@/assets/login.json";
import Eventservice from '../../services/Eventservice';
export default {
    data() {
        return {
            loginDetails: {
                emailid: '',
                password: '',
            },
            dialog: false,
            Fdialog: false,
            visible: false,
            valid: false,
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters'
            ]
        };
    },
    components: {
        signup,
        forgotpassword
    },
    mounted() {
        this.lottie1();
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.login,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
        validateForm() {
            this.$refs.form.validate();
            if (this.valid) {
                this.$loader(true)
                Eventservice.LoginApi(this.loginDetails)
                    .then(response => {
                        if (response.data.status == "S") {
                            localStorage.setItem("Token", response.data.token);
                            localStorage.setItem("Emailid", response.data.emailid);
                            localStorage.setItem("Percentage", response.data.percentage);
                            localStorage.setItem("Name", response.data.name);
                            this.$showSnackbar(response.data.status, response.data.msg);
                            if (localStorage.getItem("Name", response.data.name) && localStorage.getItem("Token", response.data.token) && localStorage.getItem("Emailid", response.data.emailid) && localStorage.getItem("Percentage", response.data.percentage)) {
                                this.$router.push("/dashboard");
                                setTimeout(() => {
                                    window.location.reload();
                                    this.$loader(false)
                                }, 200);
                            }
                        } else {
                            this.$loader(false)
                            this.$showSnackbar(response.data.status, "Login Failed");
                        }
                    })
                    .catch(error => {
                        this.$loader(false)
                        this.$showSnackbar("E", "Network Error");
                    });
            }
        },
        signup() {
            this.dialog = true
        },
        forgotpassword() {
            this.Fdialog = true
        },
        closeDialog(value) {
            this.dialog = value
        },
        closeFDialog(value) {
            this.Fdialog = value
        }
    },
};
</script>

<style scoped>
.disp1 {
    display: flex;
    align-content: center;
    justify-content: center;
}

@media (max-width: 425px) {
    .disp1 {
        display: none;
    }

    .disp2 {
        display: none;
    }
}

@media (max-width: 768px) and (min-width: 426px) {
    .disp1 {
        display: none;
    }
}
</style>