<template>
    <div>
        <div>
            <v-row class=" d-flex mt-5 align-center justify-center">
                <v-col cols="12" md="6">
                    <div class="text1  mx-2  text-orange font-weight-black">Make Count</div>
                    <div class="text1 text12  mx-2 font-weight-black">of Your Investment</div><br>
                    <h1 class="subtitle1" style="text-align: center">Investment Management Company</h1>
                    <h4 class="content1 mx-2" style="text-align: center">Our purpose is to help more and more people
                        experience financial
                        well-being. Together and We’re investing for the future on behalf of our clients.</h4>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row class="d-flex align-center justify-center">
                <v-col cols="auto">
                    <v-sheet class="pa-6 mx-auto">
                        <h1 class="text-h5 font-weight-bold mb-4">Invest in Lumpsum & SIP<br>
                            <h1 class="typeing text-orange">fixed-income opportunities</h1>
                        </h1>
                        <h2 class="text2 font-weight-thin">
                            <v-icon color="green-accent-3" size="24px">mdi-check-decagram</v-icon><b>Investment starting
                                at just
                                ₹5,000</b>
                            <v-spacer></v-spacer>
                            <v-icon color="green-accent-3" size="24px">mdi-check-decagram</v-icon><b>Get Regular Fixed
                                Income
                                Returns</b>
                            <br>
                            <v-icon color="green-accent-3" size="24px">mdi-check-decagram</v-icon><b>Invest in Lumpsum
                                and
                                Monthly</b>
                            <br>
                            <v-icon color="green-accent-3" size="24px">mdi-check-decagram</v-icon><b>Earn Commission
                                from
                                Referral</b>
                        </h2>
                    </v-sheet>
                    <v-btn @click="navigateTo('login')" class="px-12 mx-16 " color="primary" variant="outlined" >Start investing</v-btn>
                </v-col>
                <v-col cols="auto" md="5">
                    <div class="homeinvestment" ref="Homeinvestment"></div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata from "@/assets/home.json";
export default {
    mounted() {
        this.lottie1();
    },
    data() {
        return {

        }
    },
    methods: {
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestment,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
        navigateTo(routeName) {
            this.$router.push({ name: routeName });
            this.drawer = false; // Close the drawer after navigation
        },
    }
}
</script>
<style>
.text1 {
    font-size: 80px;
    margin-bottom: -0.3em;
}

@media (max-width: 425px) {
    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    .text1 {
        font-size: 35px;
    }

    .subtitle1 {
        font-size: 20px;
    }

    .text2 {
        font-size: 15px;
    }

    .content1 {
        font-size: 13px
    }

    .typeing {
        font-size: 26px;
        overflow: hidden;
        white-space: nowrap;
        animation: typing 5s steps(30, end)infinite;
        border-right: .1em solid orange;
    }

    .homeinvestment {
        width: 300px;
    }
}

@media (max-width : 768px)and (min-width : 426px) {
    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    .text1 {
        font-size: 60px;
    }

    .typeing {
        overflow: hidden;
        white-space: nowrap;
        animation: typing 5s steps(30, end)infinite;
        border-right: .1em solid orange;
    }
}

@media (max-width : 1024px)and (min-width : 769px) {
    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    .text1 {
        font-size: 50px;
    }

    .typeing {
        overflow: hidden;
        white-space: nowrap;
        animation: typing 5s steps(30, end)infinite;
        border-right: .1em solid orange;
    }
}

@media (max-width : 1440px)and (min-width : 1025px) {
    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    .typeing {
        overflow: hidden;
        white-space: nowrap;
        animation: typing 5s steps(30, end)infinite;
        border-right: .1em solid orange;
    }
}

@media (max-width : 2560px)and (min-width : 1441px) {
    @keyframes typing {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }

    .text12 {
        margin-bottom: 10px;
    }

    .typeing {
        overflow: hidden;
        white-space: nowrap;
        animation: typing 5s steps(30, end)infinite;
        border-right: .1em solid orange;
    }
}
</style>