<template>
    <div>
        <div><v-row class="d-flex align-center justify-center"><v-col class="d-flex align-center justify-center">
                    <div class="text-h2 text-orange font-weight-bold">LumpSum Funds</div>
                </v-col>
            </v-row></div>
        <div v-for="(item, i) in CardList" :key="i">
            <v-card color="blue-grey-lighten-5" :title="item.title" class="portfoliocard1 mx-auto my-2">
                <template v-slot:append>
                    <div class="text-teal">Payout {{ item.payout }}</div>
                </template>
                <template v-slot:prepend>
                    <v-icon>mdi-view-list</v-icon>
                </template>
                <v-card-subtitle>{{ item.title }}</v-card-subtitle>
                <v-card-text>
                    <v-row><v-col cols="5" class="font-weight-bold text-caption">Investment: ₹{{ item.minamount
                            }}</v-col>
                        <v-col cols="4" class="font-weight-bold text-caption text-success">Returns: {{ item.returns
                            }}%</v-col><v-col cols="2" class="font-weight-bold text-caption text-success">Exit:{{
                                item.exitload
                            }}</v-col></v-row>
                    <v-row><v-col class="pt-0" cols="12"><v-btn @click="investNow(item)" size="small"
                                variant="outlined">Invest
                                Now</v-btn></v-col></v-row>
                </v-card-text>
            </v-card>
        </div>
        <investmentDialog :Dialog="Dialog" @close="close" :Investment="Investment" />
    </div>
</template>
<script>
import investmentDialog from './investmentDialog.vue';
import Eventservice from '../../services/Eventservice';
export default {
    created() {
        this.Initialize()
    },
    props: {

    },
    watch: {

    },
    components: {
        investmentDialog
    },
    data() {
        return {
            Dialog: false,
            CardList: [],
            Investment: {
                planId: "",
                plan: "",
                investment: "",
                returns: "",
                period: "",
                payout: "",
            }
        }
    },
    methods: {
        Initialize() {
            this.$loader(true)
            Eventservice.FetchPlans("L")
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.CardList = response.data.plansList;
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Unable to Fetch Plans");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },
        investNow(value) {
            this.Investment.planId = value.planId
            this.Investment.plan = value.title
            this.Investment.investment = value.minamount
            this.Investment.returns = value.returns
            this.Investment.period = value.lockinperiod
            this.Investment.payout = value.payout
            this.Dialog = true;
        },
        close(value) {
            this.Dialog = value;
        }

    },
}
</script>
<style></style>