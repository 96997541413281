<template>
    <div>
        <services1 />
        <services2 />
        <services3 />
        <v-footer dark padless class="pa-0">
            <v-card flat tile color="blue-grey-lighten-5" class="mt-5 text-center">
                <v-card-text>
                    <v-btn v-for="icon in icons" :key="icon.name" class="mx-4" icon :href="icon.link" target="_blank">
                        <v-icon size="24px">
                            {{ icon.name }}
                        </v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text class="text-justify pt-0">
                    At Homeland Investment Management, we are dedicated to guiding individuals
                    towards a secure financial future through our specialized expertise in
                    fixed-return solutions. As fiduciaries, we prioritize the interests of our
                    clients above all else, ensuring that every investment decision is made with
                    their long-term prosperity in mind. Our comprehensive suite of services
                    encompasses prudent advice, risk management, and a steadfast commitment to
                    building trust and integrity throughout our client relationships. With Homeland,
                    you can confidently navigate the complexities of investment, knowing that your
                    financial well-being is in capable hands. Trust us to safeguard your future and
                    provide peace of mind every step of the way.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="">
                    Homeland since 2024 — <strong>© Rights reserved Version-1.1</strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </div>

</template>
<script>
import services1 from '../components/services/services1.vue'
import services2 from '../components/services/services2.vue'
import services3 from '../components/services/services3.vue'
export default {
    data() {
        return {
            icons: [
                // { name: "mdi-facebook", link: "https://www.facebook.com" },
                { name: "mdi-gmail", link: "mailto:homelandinvestment.in@gmail.com" },
                // { name: 'mdi-twitter', link: 'https://twitter.com' },
                { name: 'mdi-linkedin', link: 'https://www.linkedin.com/company/homeland-investment-management-company/' },
                { name: "mdi-instagram", link: "https://www.instagram.com/homeland_investment?igsh=dzllYXJtNXdrMXFj&utm_source=qr" }
            ],
        }
    },
    components: {
        services1,
        services2,
        services3,
    }
}
</script>