<template>
    <div>
        <investmentDetails :isSheet="isSheet" :pInvestmentDetails="pInvestmentDetails" @closeSheet="closeSheet" />
        <div><v-row class="d-flex align-center justify-center"><v-col class="d-flex align-center justify-center">
                    <div class="text-h3 text-orange font-weight-bold">Investments</div>
                </v-col>
            </v-row></div>
        <div v-for="(item, i) in investmentList" :key="i">
            <v-card color="lime-lighten-5" :title="item.title" class="portfoliocard1 mx-auto my-2">
                <template v-slot:append>
                    <div class="text-teal">Invested on {{ item.date }}</div>
                </template>
                <template v-slot:prepend>
                    <v-icon>mdi-view-list</v-icon>
                </template>
                <v-card-subtitle>{{ item.title }} <span class="px-3"><b>{{ (item.type).toUpperCase()
                            }}</b></span></v-card-subtitle>
                <v-card-text>
                    <v-row><v-col cols="6" class="font-weight-bold">Investment: ₹{{ item.investment }}</v-col>
                        <v-col cols="6" class="font-weight-bold text-success">Returns: ₹{{ item.returnAmnt
                            }}</v-col></v-row>
                    <v-row><v-col class="pt-0" cols="12"><v-btn size="small" @click="isChart(item, i)"
                                variant="outlined">Know
                                more</v-btn></v-col></v-row>
                </v-card-text>
            </v-card>
        </div>

        <div class="noHoldLottie" ref="Homeinvestment"></div>

    </div>
</template>
<script>
import lottie from "lottie-web";
import animationdata from "@/assets/noHoldings.json";
import investmentDetails from './investmentDetails.vue';
import Eventservice from '../../services/Eventservice';
export default {
    components: {
        investmentDetails,
    },
    created() {
        this.Initialize()
    },
    data() {
        return {
            investmentList: [],
            isSheet: false,
            pInvestmentDetails: {
                date: "",
                maturityDate: "",
                investment: "",
                returnAmnt: "",
                returns: "",
                currentReturns: "",
            },
        }
    },
    methods: {
        closeSheet(value) {
            this.isSheet = value
        },
        lottie1() {
            lottie.loadAnimation({
                container: this.$refs.Homeinvestment,
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: animationdata,
            });
        },
        Initialize() {
            this.$loader(true)
            Eventservice.GetClientHoldings()
                .then(response => {
                    if (response.data.status == "S") {
                        this.$loader(false)
                        this.investmentList = response.data.clientHoldings;
                        if (this.investmentList.length == 0){
                            this.lottie1()
                        }
                    } else {
                        this.$loader(false)
                        this.$showSnackbar(response.data.status, "Failed Fetch Investments");
                    }
                })
                .catch(error => {
                    this.$loader(false)
                    this.$showSnackbar("E", "Network Error");
                });
        },
        isChart(item) {
            this.pInvestmentDetails.date = item.date
            this.pInvestmentDetails.maturityDate = item.maturityDate
            this.pInvestmentDetails.investment = item.investment
            this.pInvestmentDetails.returnAmnt = item.returnAmnt
            this.pInvestmentDetails.returns = item.returns
            this.pInvestmentDetails.currentReturns = item.currentReturns
            this.isSheet = true;
        }
    },
}
</script>
<style>

.noHoldLottie{
    width: 400px;
    height: 400px;
    margin: 20px auto;
}
.portfoliocard1 {
    width: 600px;
}

@media (max-width: 425px) {
    .portfoliocard1 {
        width: 350px;
    }
}
</style>