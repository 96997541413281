<template>
    <div>
        <v-dialog v-model="isDialog">
            <v-row class="d-flex align-center justify-center">
                <v-col class="d-flex align-center justify-center" cols="12" md="6">
                    <v-card color="blue-grey-lighten-5" width="500" title="Updation of Email">
                        <template v-slot:append>
                            <v-icon @click="closeDialog" color="error" icon="mdi-close"></v-icon>
                        </template>
                        <v-card-text class="pb-0">
                            <v-text-field v-if="!isOtp" flat prepend-icon="mdi-email" variant="solo" hide-details
                                v-model="newMail" label="Enter your New Email Address"></v-text-field>
                            <v-text-field v-else flat prepend-icon="mdi-numeric" variant="solo" hide-details
                                v-model="newMail" label="Enter 6 Digit OTP"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end">
                            <v-btn color="success" variant="outlined" @click="updateEmail">Verify</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

        </v-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isDialog: false,
            isOtp: false,
            newMail: "",
            otp: ""
        }
    },
    props: {
        Dialog: Boolean,
        clientCode: String
    },
    methods: {
        updateEmail() {
            this.isOtp = true
        },
        closeDialog() {
            this.$emit("closeDialog", false)
            this.isOtp = false
        }
    },
    watch: {
        Dialog: {
            handler(newVal) {
                this.isDialog = newVal
            }
        }
    },
}
</script>