import { reactive } from 'vue';

export default {
    install(app) {
        const snackbarConfig = reactive({
            snackbar: false,
            color: 'success',
            message: '',
        });

        app.config.globalProperties.$showSnackbar = (color, message) => {
            if (color == "S") {
                color = "success";
            }else if (color == "E"){
                color = "error"
            }
            snackbarConfig.snackbar = true;
            snackbarConfig.message = message;
            snackbarConfig.color = color;
        };

        app.config.globalProperties.$hideSnackbar = () => {
            snackbarConfig.snackbar = false;
        };

        app.config.globalProperties.$snackbarConfig = snackbarConfig;
    },
};